import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Certification from "../../../components/Certification";
import PricingColumn from "../../../components/PricingColumn";

export default function Certificates({ company, setTab }) {

	const [linearProgress, setLinearProgress] = useState(25);
	const [disabledCertificates, setDisabledCertificates] = useState(true);

	useEffect(() => {
		if (['Lite', 'Free'].includes(company['subscription'])) setDisabledCertificates(true);
		else setDisabledCertificates(false);

		// Set Linear Progress
		if (company['cLevel'] == 'Bronze') setLinearProgress(50);
		else if (company['cLevel'] == 'Silver') setLinearProgress(75);
		else if (company['cLevel'] == 'Gold') setLinearProgress(100);
		else setLinearProgress(25);
	}, [company]);

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 20,
		borderRadius: 20,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			// borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#4A67FF' : '#308fe8',
		},
	}));

	return (
		<div className="certificates-page">
			{company['points'] &&
				<>
					{disabledCertificates &&
						<div className="left-upgrade-button">
							<Link className="ass fs1rem pt0-75rem data-check-box" to={`/billing/${company['_id']}`} >
								<h3 className='c6'>* Certification Requires Upgrade To Pro</h3>
							</Link>
						</div>
					}
					<div className={disabledCertificates && 'disabled-certificates-page'}>
						<div className="badges-sec c2">
							<div className="container pt2rem pb1rem df">
								<div className="badges-area">
									<div className="certificates-current">
										<div className='top-heading'>
											<h3 className='c6'>Your Progress</h3>
											<p className='c6 fs0-75rem'>Complete the modules highlighted below to the required standard to achieve Diligentsia unaudited bronze, silver or gold certification (accessed via the Company Dashboard).</p>
										</div>
										<div className="df fdc">
											{company['points'] && <span className="packge-select">
												<div className={`px1rem ${company['cLevel'] == 'None' && 'current'}`}>
													<Certification className={`z12 `} component='Certificates' level='None' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													{!disabledCertificates && company['cLevel'] == 'None' &&
														<div className="df jcc">
															<img src={require('../../../../images/active.png')} alt="active" style={{ width: '20px', height: '15px', margin: '4px 4px 0px' }} />
															<p className="ass fs1rem">Current Status</p>
														</div>
													}
												</div>
												<div className={`px1rem ${company['cLevel'] == 'Bronze' && 'current'}`}>
													<Certification className={`z12 `} component='Certificates' level='Bronze' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													{!disabledCertificates && company['cLevel'] == 'Bronze' &&
														<div className="df jcc">
															<img src={require('../../../../images/active.png')} alt="active" style={{ width: '20px', height: '15px', margin: '4px 4px 0px' }} />
															<p className="current-badge ass fs1rem">Current Status</p>
														</div>
													}
												</div>
												<div className={`px1rem ${company['cLevel'] == 'Silver' && 'current'}`}>
													<Certification className={`z12 `} component='Certificates' level='Silver' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													{!disabledCertificates && company['cLevel'] == 'Silver' &&
														<div className="df jcc">
															<img src={require('../../../../images/active.png')} alt="active" style={{ width: '20px', height: '15px', margin: '4px 4px 0px' }} />
															<p className="current-badge ass fs1rem">Current Status</p>
														</div>
													}
												</div>
												<div className={`px1rem ${company['cLevel'] == 'Gold' && 'current'}`}>
													<Certification className={`z12`} component='Certificates' level='Gold' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													{!disabledCertificates && company['cLevel'] == 'Gold' &&
														<div className="df jcc">
															<img src={require('../../../../images/active.png')} alt="active" style={{ width: '20px', height: '15px', margin: '4px 4px 0px' }} />
															<p className="current-badge ass fs1rem">Current Status</p>
														</div>
													}
												</div>
											</span>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<>
							<div className="progress-sec">
								<div className="container">
									{!disabledCertificates && <>
										{company['certificateNext'] &&
											<Box sx={{ width: '100%' }}>
												<div className='certificate-progress-bar'>
													<ProgressBar now={linearProgress} />
												</div>
												{/* <BorderLinearProgress variant="determinate" value={linearProgress} customLabel={`${company['certificateNext']} Certification Progress`} /> */}
												{/* <BorderLinearProgress variant="determinate" value={company['certificateProgress'] == 0 ? 25 : company['certificateProgress']} customLabel={`${company['certificateNext']} Certification Progress`} /> */}
											</Box>}
									</>}
									<p className='requirement-area'>* Requirements for certification progress</p>
									<div className="data-check-sec">
										<div className="dg gg2rem gtcr4">
											<div className="jcsb button-left-area">
												{disabledCertificates &&
													<div className="left-upgrade-button">
														<Link className="mr1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem cp ttu bsbb tac jcc aic cp" to={`/billing/${company['_id']}`} >Upgrade to PRO</Link>
														<p className="ass fs1rem pt0-5rem">* Upgrade to unlock Certificates</p>
													</div>
												}
											</div>
											<div className="jcsb data-check-box">
												<p className='ass fs0-75rem mb1rem fs0-75rem'><strong>Bronze</strong> Unaudited Certification Progress</p>
												<ul className="data-list">
													<li className={!disabledCertificates && company['points']['Bronze']['p1'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Data Repository</h4>
														<p>Completness <span className="c7"> &#10097; 75%</span></p>
													</li>
													<li className={!disabledCertificates && company['points']['Bronze']['p2'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Business Audit</h4>
														<p>Completness <span className="c7"> &#10097; 75%</span> &nbsp;-&nbsp; Risk <span className="c9"> &#10096; 50%</span></p>
													</li>
												</ul>
											</div>

											<div className="jcsb data-check-box">
												<p className='ass fs0.7rem mb1rem fs0-75rem'><strong>Silver</strong> Unaudited Certification Progress</p>
												<ul className="data-list">
													<li className={!disabledCertificates && company['points']['Silver']['p1'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Data Repository</h4>
														<p>Completness <span className="c7"> &#10097; 90%</span></p>
													</li>
													<li className={!disabledCertificates && company['points']['Silver']['p2'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Business Audit</h4>
														<p>Completness <span className="c7"> &#10097; 90%</span> &nbsp;-&nbsp; Risk <span className="c9"> &#10096; 50%</span></p>
													</li>

													<li className={!disabledCertificates && company['points']['Silver']['p3'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Warranty Disclosure Schedule</h4>
														<p>Completness <span className="c7"> &#10097; 75%</span></p>
														{disabledCertificates && <p className="fs0-75rem">(Requires upgrade to Pro)</p>}
													</li>
													<li className={!disabledCertificates && company['points']['Silver']['p4'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Director’s Questionaire</h4>
														<p>Completness <span className="c7"> &#10097; 75%</span> &nbsp;-&nbsp; Risk <span className="c9"> &#10096; 50%</span></p>
														{disabledCertificates && <p className="fs0-75rem">(Requires upgrade to Pro)</p>}
													</li>
												</ul>
											</div>

											<div className="jcsb data-check-box">
												<p className='ass fs0.7rem mb1rem fs0-75rem'><strong>Gold</strong> Unaudited Certification Progress</p>
												<ul className="data-list">
													<li className={!disabledCertificates && company['points']['Gold']['p1'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Data Repository</h4>
														<p>Completness <span className="c7"> &#10097; 100%</span></p>
													</li>
													<li className={!disabledCertificates && company['points']['Gold']['p2'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Business Audit</h4>
														<p>Completness <span className="c7"> &#10097; 100%</span> &nbsp;-&nbsp; Risk <span className="c9"> &#10096; 25%</span></p>
													</li>

													<li className={!disabledCertificates && company['points']['Gold']['p3'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Warranty Disclosure Schedule</h4>
														<p>Completness <span className="c7"> &#10097; 90%</span></p>
														{disabledCertificates && <p className="fs0-75rem">(Requires upgrade to Pro)</p>}
													</li>
													<li className={!disabledCertificates && company['points']['Gold']['p4'] && 'check'}>
														<span className="icon round"></span>
														<span className="icon check"><img src={require(`../../../../images/check.png`)} alt="" /></span>
														<h4>Director’s Questionaire</h4>
														<p>Completness <span className="c7"> &#10097; 90%</span> &nbsp;-&nbsp; Risk <span className="c9"> &#10096; 50%</span></p>
														{disabledCertificates && <p className="fs0-75rem">(Requires upgrade to Pro)</p>}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="footer-sec">
								<div className="container py2rem">
									<div className='check-box'>
										<p className="c2 tac">Audited <b>Certificates</b></p>
										<div className="certificates-current">
											<div className="df fdc">
												<span className="packge-select fw300 c4 lh1 fs3rem dg gg2rem gtcr3">
													<Certification className={'z12'} component='Certificates' level='None' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													<Certification className={'z12'} component='Certificates' level='Bronze' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													<Certification className={'z12'} component='Certificates' level='Silver' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
													<Certification className={'z12'} component='Certificates' level='Gold' subscription={company['subscription']} expiryTimestamp={company['createdAt']} company={company} />
												</span>
											</div>
										</div>
										<div className='center-text btn-back mt4rem mb3rem'>
											<Link className='upgrade-btns shadow bg3 c1 td250 br5px px1rem py0-5rem cp ttu bsbb tac jcc aic'>Upgrade to Pro PLUS</Link>
											<div className="certification-pricing-column">
												<PricingColumn plan="Pro PLUS" component="Certificates" />
											</div>
											<br />
											<img src={require('../../../../images/landingpage/book-call.png')} style={{ width: '15%' }} className="cp book-call" onClick={() => window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')} />
											<br />
											<button onClick={() => setTab('')} className='shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem cp ttu bsbb tac jcc aic'>Back</button>
										</div>
									</div>
								</div>
							</div>
						</>
					</div>
				</>
			}
		</div>
	);
};
