import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useState, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../reducers/modalReducer";
import { updateModule } from '../../reducers/moduleReducer';
import { postRequest, showMessage } from "../../config";


export default function CoreWorkspaceButton({ buttonClass = "", permissionLevel, step, title, type, infoJSX, directors, directorsStats, company, moduleCertification,
	module, setTab, getFitInvestorFirms }) {

	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);


	const setModuleTab = async (setTab, type, title) => {
		// CHECK KPI & CERTIFICATE FOR GUEST USERS
		if (user?.['role'] == 'Guest') {
			if (type == "test-certification") dispatch(toggleModal({ open: 'noRegistrationCertificate' }))
			else dispatch(toggleModal({ open: 'noRegistrationReport' }))
			return;
		}
		// CHECK EXPANSION PACK PERMISSIONS FOR MEMBERS
		if (user['role'] == 'Member' && user['_id'] !== company['creatorId']['_id']) {
			if (type == 'software-development' && (!company?.['memberPermissions']?.[user['_id']]?.['DEPN004'])) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'esg' && (!company?.['memberPermissions']?.[user['_id']]?.['DEPF005'])) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'warranty-disclosure' && (!company?.['memberPermissions']?.[user['_id']]?.['DEPF006'])) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'directors-questionnaire' && (!company?.['memberPermissions']?.[user['_id']]?.['DEPF007'])) {
				showMessage('To access this module please contact your account admin');
				return;
			}
		}
		// CHECK EXPANSION PACK PERMISSIONS FOR ADVISORS
		if (user['role'] == "Advisor" && company['advisors'] && company['advisors']?.[user['_id']] && company?.['advisors']?.[user['_id']]?.['modules']) {
			if (type == 'software-development' && !company?.['advisors']?.[user['_id']]?.['modules']?.['DEPN004']) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'esg' && !company?.['advisors']?.[user['_id']]?.['modules']?.['DEPF005']) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'warranty-disclosure' && !company?.['advisors']?.[user['_id']]?.['modules']?.['DEPF006']) {
				showMessage('To access this module please contact your account admin');
				return;
			}
			if (type == 'directors-questionnaire' && !company?.['advisors']?.[user['_id']]?.['modules']?.['DEPF007']) {
				showMessage('To access this module please contact your account admin');
				return;
			}
		}
		if ((title == "KPI Report (FREE Bonus Module)" || title == "KPI Report") && company['subscription'] == "Free") {
			if (module?.['stats']?.['completionPercent'] == 0) return showMessage(`${module['heading']} Not Started!`)
			localStorage.setItem('moduleBox', 'Bonus Module');
		}
		if (type.includes("directors-questionnaire")) {
			dispatch(toggleModal({ open: 'showDirectors' }));
			return;
		}
		else if (type == "test-certification" && module?.stats?.completionPercent == 0) {
			showMessage('Please start the Test now to get your Certification')
			return;
		}
		else {
			setTab(type);
			if (module) dispatch(updateModule(module));
		}
	};

	const handleLinkModuleTab = (type) => {
		if (user?.['role'] == 'Guest' && (type == `two-minute-test-${company?.['testType']}` || type == "kpi-report" || type == "test-certification")) return '#';
		if (type == 'directors-questionnaire') return '#';
		return `#${type}`;
	};

	const setReportTab = async (type) => {
		if (type == 'investor-lead') {
			getFitInvestorFirms();
			return;
		}
		setTab(type + '-report')
	};

	const showPercent = (value, type) => {
		let color;
		if (type == 'Risk') color = value > 67 ? 'red' : value > 33 ? 'yellow' : 'green';
		else if (type == 'Complete') color = value > 67 ? 'green' : value > 33 ? 'yellow' : 'red';
		return <h4 className={`${type == 'Complete' && 'mb0-5rem'} ${color} fs0-75rem lh1 mb0-5rem`}>{value || 0}%<br /><span className='c6'>{type}</span></h4>
	};

	const showPopupPercent = (value, type) => {
		let color;
		if (type == 'Risk') color = value > 67 ? 'red' : value > 33 ? 'yellow' : 'green';
		else if (type == 'Completeness') color = value > 67 ? 'green' : value > 33 ? 'yellow' : 'red';
		return <span className={`${color}`}>{value || 0}% <span className='c6'>{type}</span></span>
	};

	const createDirector = async () => {
		const companyId = location.pathname.split("/").slice(-1)[0];
		const name = document.getElementById("directorName").value;
		const designation = document.getElementById("directorDesignation").value;
		if (name && designation) {
			const postBody = { name, designation };
			const response = await postRequest(`/company/createDirector/${companyId}`, postBody);
			if (response && response['code'] == 200) {
				// getCompany();
				dispatch(toggleModal({ open: '' }));
				showMessage(response['message']);
				location.reload();
			}
		} else showMessage("Enter director name and designation");
	};

	const navigateSection = async (type, sectionNo) => {
		if (user?.['role'] == 'Guest') {
			dispatch(toggleModal({ open: 'noRegistrationReport' }))
			return;
		}

		await setTab(type);
		setTimeout(() => {
			let element = document.getElementById(sectionNo);
			if (element) element.scrollIntoView({ behavior: 'instant' }, true);
		}, 1000);
	};

	const getHelpLink = (type) => {
		if (type.startsWith('two-minute-test-')) {
			return 'https://intercom.help/diligentsia-limited/en/collections/6922239-2-minute-test-from-dashboard';
		}

		if (type == 'kpi-report') {
			return 'https://intercom.help/diligentsia-limited/en/articles/8557593-key-performance-indicator-kpi-reporting';
		}

		if (type == 'test-certification') {
			return 'https://intercom.help/diligentsia-limited/en/collections/6775868-your-certification';
		}

		if (type == 'upgrade') {

		}

		if (type == 'checklist-builder') {

		}

		if (type == 'repository') {
			return 'https://intercom.help/diligentsia-limited/en/collections/6737385-your-diligentsia-data-repository';
		}

		if (type.startsWith('business-audit-')) {
			return 'https://intercom.help/diligentsia-limited/en/collections/6775860-your-business-audit';
		}

		if (type == 'unaudited-certification') {
			return 'https://intercom.help/diligentsia-limited/en/collections/6775868-your-certification';
		}

		if (type == 'warranty-disclosure') {
			return 'https://intercom.help/diligentsia-limited/en/articles/8557715-expansion-pack-warranty-disclosure-module';
		}

		if (type == 'directors-questionnaire') {
			return 'https://intercom.help/diligentsia-limited/en/articles/8557468-directors-questionnaire-module';
		}

		if (type == 'software-development') {
			return 'https://intercom.help/diligentsia-limited/en/articles/8557691-expansion-pack-software-development';
		}

		if (type == 'esg') {
			return 'https://intercom.help/diligentsia-limited/en/articles/8557477-expansion-pack-environmental-social-governmental-esg';
		}

		return '';
	};

	const moduleTooltip = (sections) => {
		return (
			<div className='module-tooltip-box'>
				<svg data-tooltip="" className="w1rem h1rem f3 pa t0 r0 m0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path className="fa-primary pen" d="M80 48h-64C7.156 48 0 55.16 0 64v64c0 8.844 7.156 16 16 16h64C88.84 144 96 136.8 96 128V64C96 55.16 88.84 48 80 48zM80 208h-64C7.156 208 0 215.2 0 224v64c0 8.844 7.156 16 16 16h64C88.84 304 96 296.8 96 288V224C96 215.2 88.84 208 80 208zM80 368h-64C7.156 368 0 375.2 0 384v64c0 8.844 7.156 16 16 16h64C88.84 464 96 456.8 96 448v-64C96 375.2 88.84 368 80 368z" />
					<path className="fa-secondary pen" d="M192 128h288c17.67 0 32-14.33 32-31.1S497.7 64 480 64H192C174.3 64 160 78.33 160 95.1S174.3 128 192 128zM480 384H192c-17.67 0-32 14.33-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32S497.7 384 480 384zM480 224H192C174.3 224 160 238.3 160 256s14.33 32 32 32h288c17.67 0 32-14.33 32-32S497.7 224 480 224z" />
				</svg>

				{sections.length > 0 &&
					<span className="tooltip-area">
						{sections.map((section, idx) => {
							if (!section.stats) section.stats = module['sections'][0]['stats'];
							const completeness = section.stats.completionPercent;

							let riskColour;
							if (module.type === "repository" || section.stats.riskPercent < 38) riskColour = 7;
							else if (section.stats.riskPercent > 62) riskColour = 9;
							else riskColour = 8;

							let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";

							let colorChange = false;
							if (module['type'] == "warranty-disclosure") {
								if (section?.questions && section?.questions.length > 0) section?.questions.map(question => {
									if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
										colorChange = true;
										return;
									}
								})
							}
							return <button key={idx} onClick={() => navigateSection(type, section['sectionNo'])} style={{ background: `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className="overlay-box tal w100 tac cp c3-hover"><span>{section['sectionName']}</span></button>
						})}
					</span>
				}
			</div>
		)
	};

	const directorModuleTooltip = (items) => {
		return (
			<div className='module-tooltip-box'>
				{items.length > 0 &&
					<span className="tooltip-area">
						{items.map((item, idx) => {
							let completeness = (company['moduleStats']?.[`directors-questionnaire_${item?._id}`]?.['stats']?.['completionPercent'] ? company['moduleStats']?.[`directors-questionnaire_${item?._id}`]?.['stats']?.['completionPercent'] : 0);
							let riskColour;
							if (company['moduleStats']?.[`directors-questionnaire_${item?._id}`]?.['stats']?.['riskPercent'] && company['moduleStats']?.[`directors-questionnaire_${item?._id}`]?.['stats']?.['riskPercent'] < 38) riskColour = 7;
							else if (company['moduleStats']?.[`directors-questionnaire_${item?._id}`]['stats']?.['riskPercent'] && company['moduleStats']?.[`directors-questionnaire_${item?._id}`]['stats']?.['riskPercent'] > 62) riskColour = 9;
							else riskColour = 8;

							let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";

							return <div key={idx} style={{ background: `linear-gradient(90deg, ${hexColour} 0%, ${hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} className="overlay-box tal"><span>{item['name']}</span></div>
						})}
					</span>
				}
			</div>
		)
	};

	const handleRegisterFree = () => {
		dispatch(toggleModal({ open: '' }));
		if (company) dispatch(updateCompany(company));
	};

	return (
		<Fragment>
			<div className={`${buttonClass} ${user?.['role'] == 'Guest' ? 'bo3' : 'bo7'} btn-packge w100 ttc pr bss bw3px bg5 c6 br5px tac df jcc aic`}>
				{getHelpLink(type) != '' && <Link to={getHelpLink(type)} target="_blank" className="help-area" style={{ marginLeft: 'auto', marginRight: 'auto', position: 'absolute', top: '-6px', left: infoJSX ? '16px' : '-8px', zIndex: '8' }}>
					<i className="icon">
						<img className="question-button" src={require(`../../../images/pink-question.svg`)} alt="" />
					</i>
				</Link>}
				<Link to={handleLinkModuleTab(type)} onClick={() => setModuleTab(setTab, type, title)} className="ttc pr btn-packge c6 px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic zi1 w100">
					{title == "Directors Questionnaire" && directorsStats ? (
						<span className="tooltip-area style-two">
							<div className="percentage-area df fdc jcc aic">
								{showPercent(directorsStats?.completionPercent || 0, 'Complete')}
								{showPercent(directorsStats?.riskPercent || 0, 'Risk')}
							</div>
						</span>
					) : module?.stats && <>{
						type !== "test-certification" && type !== "kpi-report" && (
							<span className="tooltip-area style-two">
								<div className="percentage-area df fdc jcc aic">
									{showPercent(module.stats.completionPercent || 0, 'Complete')}
									{(type != 'repository' && type != 'investor-lead') && showPercent(module.stats.riskPercent || 0, 'Risk')}
								</div>
							</span>
						)}
					</>}
					{title}
					{moduleCertification && <>
						{moduleCertification == 'None' && <div className="ribbon ribbon-bottom-right grey"><img className="h4rem" src={require(`../../../images/insuffiient-label.png`)} alt="" /></div>}
						{moduleCertification == 'Bronze' && <div className="trophy-cup ribbon ribbon-bottom-right bronze"><img className="h4rem" src={require(`../../../images/bronze-cup.png`)} alt="" /></div>}
						{moduleCertification == 'Silver' && <div className="trophy-cup ribbon ribbon-bottom-right silver"><img className="h4rem" src={require(`../../../images/silver-cup.png`)} alt="" /></div>}
						{moduleCertification == 'Gold' && <div className="trophy-cup ribbon ribbon-bottom-right gold"><img className="h4rem" src={require(`../../../images/gold-cup.png`)} alt="" /></div>}
						{moduleCertification == 'Gold' && <div className="trophy-cup ribbon ribbon-bottom-right gold"><img className="h4rem" src={require(`../../../images/gold-cup.png`)} alt="" /></div>}
						{moduleCertification == 'Pass' && <div className="ribbon ribbon-bottom-right"><img className="h4rem" src={require(`../../../images/pass-label.png`)} alt="" /></div>}
						{moduleCertification == 'Fail' && <div className="ribbon ribbon-bottom-right"><img className="h4rem" src={require(`../../../images/fail-label.png`)} alt="" /></div>}
					</>}
					{(user['role'] == 'Guest' && step) && <span className='step-box'>Step {step}</span>}
					{type.includes("directors-questionnaire") &&
						<span className='step-box text-gradient' style={{ fontSize: '12px' }}>({directors.length}) - Directors</span>
					}

					{/* Pass Fail Certification add images */}
					{type == "test-certification" && user['role'] == 'Guest' &&
						<div className='certifcate-box-pass-fail pa r0 t0 df fdc'>
							<img className=" mb0-5rem" src={require(`../../../images/fail.png`)} alt="" />
							<img className="" src={require(`../../../images/pass.png`)} alt="" />
						</div>
					}
				</Link>
				{infoJSX && (
					<div className="btn-packge tac df jcc aic" style={{ position: 'static', zIndex: '9' }}>
						<span className="tooltip-area" style={{ left: '-8px', right: 'auto' }}>
							<i className="icon">
								<img className="h4rem" src={require(`../../../images/info-icon.svg`)} alt="" />
								{(type != 'unaudited-certification' || company?.['subscription'] != 'Lite') &&
									<div className="overlay-box">
										{infoJSX}
									</div>

								}
							</i>
						</span>
					</div>
				)}
				{module
					? (type !== "test-certification" && type !== "kpi-report") && moduleTooltip(module['expansionPack'] ? Object.values(module['sections'][0]['subFolders']) : module.sections)
					: title == "Directors Questionnaire" && directorModuleTooltip(directors)
				}
				{(permissionLevel == "ALL" || permissionLevel == "DASHBOARD & REPORT") && <>
					{(module?.stats?.completionPercent > 0 && type !== "kpi-report" && type !== "test-certification" && !type.includes("directors-questionnaire") && user?.['role'] !== 'Guest') &&
						<Link onClick={() => setReportTab(type)} className={`w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r`}>R</Link>
					}
				</>}
			</div>

			{/* DIRECTOR'S POPUSPS */}
			{type.includes("directors-questionnaire") && <>
				{/* Show Directors */}
				<Modal open={modal['open'] == 'showDirectors'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop showDirectors td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
					<div className="pr maw800px inner-showDirectors pr">
						<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
							<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
							</svg>
						</button>
						<div className='top-header'>
							<h1 className="text-gradient fw600 tac mb1rem">LIST OF QUESTIONNAIRES</h1>
							<button onClick={() => dispatch(toggleModal({ open: 'addDirector' }))} className="cp tac c1 bg3 br5px shadow px0-25rem py0-5rem fw500 director-add">Add Director/Senior Manager​</button>
						</div>
						<div className='table-area-showDirectors'>
							<table id="table" className="table-showDirectors">
								<thead className="ps t0">
									<tr>
										<th>Name</th>
										<th>Designation</th>
										<th>Progress</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{directors && directors.length > 0 && <>
										{directors.map((item, idx) => (
											<tr key={idx}>
												<td className={`${item['headShot'] && 'df aic '} c6 fw500 ttc`}>
													{item['headShot'] && <img src={item['headShot']['location']} className="w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem" />}
													{item['name']}
												</td>
												<td className='c6 fw500 ttc'>{item['designation']}</td>
												<td className='p1rem c6 fw500 ttc'>({showPopupPercent(company?.['modules']?.[`directors-questionnaire_${item['_id']}`]?.['stats']?.['completionPercent'] || 0, 'Completeness')} / {showPopupPercent(company?.['modules']?.[`directors-questionnaire_${item['_id']}`]?.['stats']?.['riskPercent'] || 0, 'Risk')})</td>
												<td><Link to={`#${type}_${item['_id']}`} onClick={() => setTab(`${type}_${item['_id']}`)} className="cp bg4 bg3-hover c1 tac c1 shadow px1rem py0-5rem br5px fw500">View</Link></td>
											</tr>
										))}
									</>}
								</tbody>
							</table>
							{directors && directors.length == 0 &&
								<div className='tac py1rem'>
									<h2 className="text-gradient fw600">No Directors Added</h2>
								</div>
							}
							<div className='logo-modal'>
								<img className="h2rem" src={require(`../../../images/logo.svg`)} alt="TODO" />
							</div>
						</div>
					</div>
				</Modal>

				{/* Add Directors */}
				<Modal open={modal['open'] == 'addDirector'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
					<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
						<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
							<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
							</svg>
						</button>
						<h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">ADD DIRECTOR</h1>
						<div className="df fdc gg1rem">
							<div className="pr">
								<input id="directorName" type="text" placeholder="Name of Director" className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
									<path
										d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
										className="fa-secondary"></path>
								</svg>
							</div>
							<div className="pr">
								<input id="directorDesignation" type="text" placeholder="Designation of Director" className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path className="fa-primary" d="M512 144v288c0 26.5-21.5 48-48 48h-416C21.5 480 0 458.5 0 432v-288C0 117.5 21.5 96 48 96h416C490.5 96 512 117.5 512 144z" />
									<path className="fa-secondary" d="M176 96H128V48C128 21.5 149.5 0 176 0h160C362.5 0 384 21.5 384 48V96h-48V48h-160V96z" />
								</svg>
							</div>
							<span onClick={() => createDirector()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic">
								<svg className="pen ml0-5rem h1rem f1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
									<path className="fa-primary"
										d="M616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z" />
									<path className="fa-secondary"
										d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
								</svg>
								Add Director
							</span>
							<span onClick={() => dispatch(toggleModal({ open: 'showDirectors' }))} className="c3-hover td250 fs1-25rem cp">Back to List of Questionaire's</span>
						</div>
					</div>
				</Modal>
			</>
			}

			{/* No Registration Report Popup */}
			<Modal open={modal['open'] == 'noRegistrationReport'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div class='mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw700px pr pt5rem br5px pr'>
					<button className='close-popup' onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className='pen fds h1rem f1 mr0-5rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
							<path className='pen fa-primary' d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
						</svg>
					</button>
					<img className='h2rem pa t0 l0 m1rem' src={require(`../../../images/logo.svg`)} alt='TODO' />
					<h1 className="text-gradient fw600">Registration</h1>
					<div className='df fdc jcc my1rem'>
						<div className='tac'>
							<p className='mb1-5rem'>To view or store/print your company reports, <br /> you need to register (no credit card required).</p>
						</div>
						<div className='df jcc mt1rem'>
							<Link to='/register' onClick={() => dispatch(toggleModal({ open: '' }))} className="registerButtonAnimation shadow bsbb jcc df fdc aic fw500  px1rem c1 bg4 br19px cp" style={{ width: '40%', paddingTop: '3px', paddingBottom: '3px' }}>
								<span>Register for FREE</span>
								<span className="fw400 c21 df fdc" style={{ marginTop: "-2px", fontSize: '10px', lineHeight: '15px', fontStyle: 'italic' }}>No credit card required</span>
							</Link>
						</div>
					</div>
				</div>
			</Modal>

			{/* No Registration Certificate Popup */}
			<Modal open={modal['open'] == 'noRegistrationCertificate'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div class='mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw700px pr pt5rem br5px pr'>
					<button className='close-popup' onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className='pen fds h1rem f1 mr0-5rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
							<path className='pen fa-primary' d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
						</svg>
					</button>
					<img className='h2rem pa t0 l0 m1rem' src={require(`../../../images/logo.svg`)} alt='TODO' />
					<h1 className="text-gradient fw600">Registration</h1>
					<div className='df fdc jcc my1rem'>
						<div className='tac'>
							<p className='mb1-5rem'>To view or download your certificate, <br /> you need to register (no credit card required).</p>
						</div>
						<div className='df jcc mt1rem'>
							<Link to='/register' onClick={() => handleRegisterFree()} className="registerButtonAnimation shadow bsbb jcc df fdc aic fw500  px1rem c1 bg4 br19px cp" style={{ width: '40%', paddingTop: '3px', paddingBottom: '3px' }}>
								<span>Register for FREE</span>
								<span className="fw400 c21 df fdc" style={{ marginTop: "-2px", fontSize: '10px', lineHeight: '15px', fontStyle: 'italic' }}>No credit card required</span>
							</Link>
						</div>
					</div>
				</div>
			</Modal>

		</Fragment>
	);
}