import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";

export default function AboutUs() {
	return (
		<AnimatedPage>
			<div className="pt5rem">
				<div className="fs1rem container mb5rem df fdc">
					<HeadingSubheadingContent wrapperClass="tac mb1rem" heading="About Us" subheading="Our Story" />
					<div className="mb1rem df aifs">
						<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
						</svg>
						<span className="w100">Diligentsia was formed by three founders, all angel investors and two of us accountants, frustrated at the poor quality of smaller companies' investor information presented to us when pitched for our investment.  In running our previous high growth technology businesses, some of which we have taken from startup to IPO, we have come to value having within our organisations a central corporate data repository where we can easily organise and find key corporate data.  We quickly learned that being well organised made attracting investment easier - VCs and large strategic partners were impressed, and the time taken by due diligence was compressed.</span>
					</div>
					<div className="mb1rem df aifs">
						<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
						</svg>
						<span className="w100">When we looked at the market we realised that existing data room solutions were aimed at large companies and/or their expensive professional advisors and were themselves very expensive to set up and maintain.</span>
					</div>
					<div className="mb1rem df aifs">
						<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
						</svg>
						<span className="w100">We believe that there is room in the market for a comprehensive solution designed for smaller companies to set up and maintain, at an affordable monthly cost - and that is what we have built.</span>
					</div>
					<div className="mb1rem df aifs">
						<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
						</svg>
						<span className="w100">As well as offering solutions for SMEs themselves, we have developed solutions for angel networks and firms of accountants and lawyers to offer to their own investee companies and clients to standardise the structure of corporate data.</span>
					</div>
					<div className="mb1rem df aifs">
						<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
						</svg>
						<span className="w100">Talk to us to see how we can assist you.</span>
					</div>
				</div>
				<div className="bg6 py5rem fs1-25rem">
					<div className="container c1 df jcsb cg5rem rg2rem">
						<div className="df fdc jcc aic w50">
							<img  src={require(`../../images/mark_bernstein.png`)} alt="TODO" />
							<h2 className="bg3 c1 shadow px1rem py0-5rem br5px fs2rem tac">Mark Bernstein ACA Co-Founder</h2>
						</div>
						<div className="lh3 w50">
							<div className="mb1rem df aifs">
								<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">I have been involved in and chairing smaller companies for 30 years.</span>
							</div>
							<div className="mb1rem df aifs">
								<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">As an investor, I am frequently unable to invest because the company cannot demonstrate that it is "investment ready".</span>
							</div>
							<div className="mb1rem df aifs">
								<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Where I am appointed Chairman, the first thing I do is ask that all the siloed corporate information around the organisation is uploaded into a single structured "living" data room with the user of checklists. This increases efficiency by making key information more available to the team, enabling me to undertake my own comprehensive due diligence on the state of the business, and better prepares us for quicker investment/growth/sale. My boards undertake an internal Disclosure Schedule review process every quarter linked to that living data room.</span>
							</div>
							<div className="mb1rem df aifs">
								<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">
									We have created Diligentsia to develop a platform that distils that process experience into a SaaS solution that is streamlined, easy to use and scalable. <span className="fw600">We are data room provider agnostic - we focus on the processing of relevant data upload and adding value to that data.</span>
								</span>
							</div>
							<span className="fs1-5rem fw600 c4 w100">&hellip;in short, a data collection business for the data-room world.</span>
						</div>
					</div>
				</div>
				<div className="container py5rem df jcsb gg2rem">
					<div className="df fdc w30">
						<img className="h200px ofcon" src={require(`../../images/mark_bernstein.png`)} alt="TODO" />
						<h2 className="w100 bsbb c1 bg3 px1rem py0-5rem shadow br5px fs2rem tac mb2rem">Mark Bernstein ACA</h2>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Experienced technology sector growth manager/CEO/NEC</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Qualified chartered accountant with EY 30 years on listed company boards</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">IPO experience (4 companies to IPOs on LSE/AIM)</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Taken a start up to $1B (briefly!) on AIM</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Experienced & proven fund raiser having previously taken equity investment from IBM, Motorola, BT, Sky, Dixons</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Start-ups/scaleups and 25+ acquisitions, disposals & exits</span>
						</div>
					</div>
					<div className="df fdc w30">
						<img className="h200px ofcon" src={require(`../../images/matthew_clark.png`)} alt="TODO" />
						<h2 className="w100 bsbb c1 bg3 px1rem py0-5rem shadow br5px fs2rem tac mb2rem">Matthew Clark ACMA</h2>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Experienced CFO of VC-backed technology companies (both US and UK)</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Strong commercial viewpoint, has previously also acted as COO.</span>
						</div>
					</div>
					<div className="df fdc w30">
						<img className="h200px ofcon" src={require(`../../images/conor_davey.png`)} alt="TODO" />
						<h2 className="w100 bsbb c1 bg3 px1rem py0-5rem shadow br5px fs2rem tac mb2rem">Conor Davey</h2>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Part of the core executive team that over 20 plus years took Williams Lea from £1M to £1.4B of global revenue, latterly as Group CEO managing 10,000 plus FTEs in 33 countries</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Demonstrable experience in scaling B2B services businesses in UK and internationally</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Strong track record of designing, closing and delivering long term revenue agreements with large corporates and government organisations</span>
						</div>
						<div className="mb1rem df aifs">
							<svg className="w2rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
							</svg>
							<span className="w100">Angel investor in early-stage companies.</span>
						</div>
					</div>
				</div>
			</div>
		</AnimatedPage>
	);
}
