import moment from "moment";
import base64 from 'base-64';
import html2pdf from "html2pdf.js";
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import Report from "./Report";
import QuesAns from "./QuesAns";
import DDQuesAns from "./DDQuesAns"
import FitReport from "./FitReport";
import Dashboard from "./Dashboard";
import SidebarIndex from "./SidebarIndex";
import Certificates from "./Certificates";
import AllReports from "../Advisor/AllReports";
import CompanySettings from "./CompanySettings";
import CompanyAudit from "../Advisor/CompanyAudit";
import { BILLING } from "../../../constants/routes";
import Dropbox from "../../../components/svgs/Dropbox";
import BenchmarkReport from "../Advisor/BenchmarkReport";
import ChecklistReport from "../Advisor/ChecklistReport";
import { updateUser } from '../../../reducers/userReducer';
import AnimatedPage from "../../../components/AnimatedPage";
import { toggleModal } from "../../../reducers/modalReducer";
import SharePoint from "../../../components/svgs/SharePoint";
import Certification from "../../../components/Certification";
import GoogleDrive from "../../../components/svgs/GoogleDrive";
import TestCertificate from "../../../components/TestCertificate";
import PassFailCertification from "../../../components/PassFailCertification";
import socket, { getRequest, postRequest, showMessage } from "../../../config";

export default function Company(props) {

	let dispatch = useDispatch();
	const navigate = useNavigate()

	let location = useLocation();
	let user = useSelector(state => state.user);
	let modal = useSelector(state => state.modal);

	let [company, setCompany] = useState({});
	let [editUser, setEditUser] = useState({});
	let [moduleAI, setModuleAI] = useState('');
	let [advisors, setAdvisors] = useState([]);
	let [investors, setInvestors] = useState([]);
	let [companyId, setCompanyId] = useState({});
	let [selectAll, setSelectAll] = useState(false);
	let [isLeadPdf, setIsLeadPdf] = useState(false);
	let [userModules, setUserModules] = useState([]);
	let [aiEnhanced, setAiEnchaned] = useState(false);
	let [openTabs, setOpenTabs] = useState(new Set());
	let [isPortfolio, setIsPortfolio] = useState(false);
	let [acceptEmail, setAcceptEmail] = useState(false);
	let [investorEmail, setInvestorEmail] = useState({});
	let [checklistTask, setChecklistTask] = useState([]);
	let [userAction, setUserAction] = useState('Search');
	let [companyIndex, setCompanyIndex] = useState(false);
	let [deleteMemberId, setDeleteMemberId] = useState('');
	let [selectedModules, setSelectedModules] = useState({});
	let [auditReportLogs, setAuditReportLogs] = useState({});
	let [fitInvestorFirms, setFitInvestorFirms] = useState([]);
	let [permissionLevel, setPermissionLevel] = useState('ALL');
	let [profileClass, setProfileClass] = useState('client-profile');
	let [isTwoMinuteTesHomePage, setIsTwoMinuteTesHomePage] = useState(false);
	let [tab, setTabName] = useState(props && props?.isCallback ? 'repository' : '');
	const [moduleContent] = useState({
		'kpi': {
			heading: 'KPI',
			aiEnhanced: true,
			uploadFiles: false,
			subheading: 'Key Performance Indicators'
		}, 'repository': {
			aiEnhanced: false,
			uploadFiles: true,
			heading: 'Repository',
			subheading: 'Securely store all your important documents here'
		}, 'business-audit-investment': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Business Audit',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'business-audit-growth': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Business Audit',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'business-audit-exit': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Business Audit',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'directors-questionnaire': {
			aiEnhanced: false,
			uploadFiles: true,
			heading: 'Directors Questionnaire',
			subheading: ''
		}, 'two-minute-test-exit': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Exit Ready Test',
			subheading: 'See How Exit Ready You Are'
		}, 'two-minute-test-growth': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Growth Ready Test',
			subheading: 'See How Growth Ready You Are'
		}, 'esg': {
			aiEnhanced: false,
			uploadFiles: true,
			heading: 'Environmental, Social & Governmental',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'software-development': {
			aiEnhanced: false,
			uploadFiles: true,
			heading: 'Software Development',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'warranty-disclosure': {
			aiEnhanced: false,
			uploadFiles: true,
			heading: 'Warranty Disclosure Schedule',
			subheading: 'Get expert recommendations on how to reduce risk'
		}, 'two-minute-test-investment': {
			aiEnhanced: true,
			uploadFiles: false,
			heading: 'Investment Ready Test',
			subheading: 'How Investment Ready Are You'
		}, 'unaudited-certification': {
			sections: [],
			heading: 'Certification',
			subheading: 'This is your current certification',
		}, 'investor-lead': {
			uploadFiles: true,
			heading: 'Investor Lead Introduction',
			subheading: 'Securely store all your important documents here'
		}
	});
	const sections = ['D001', 'D002', 'D003', 'D003', 'D004', 'D005', 'D006', 'D007', 'D008', 'D009', 'D010', 'D011', 'D012', 'D013', 'D014', 'D015', 'D016', 'D017', 'D018', 'D019', 'D020',
		"DEPF005", "DEPF006", "DEPF007", "DEPN004"];

	const initialized = useRef(false);
	useEffect(() => {
		if (user && (Object.keys(user).length == 0 || !user['stripeMethods'])) getUser();
		if (user && user['role'] !== 'Guest') getAdvisors();
		if (!initialized.current) {
			initialized.current = true
			if (location.pathname.includes('gDriveCallBack')) connectGDrive();
			else if (location.pathname.includes('dropBoxCallBack')) connectDropBox();
			else if (location.pathname.includes('sharePointCallBack')) connectSharePoint();
			else {
				let moduleName = window.location.hash.replace('#', '');
				getCompany(moduleName);
				document.querySelectorAll(".sectionWrapper").forEach(el => observer.observe(el));
			}
		}
	}, []);

	useEffect(() => {
		if (user) setProfileClass(user['role'] == 'Advisor' ? 'advisor-profile' : user['role'] == 'Investor' ? 'investor-profile' : 'client-profile')
	}, [user]);

	useEffect(() => {
		socket.on('aireport:generated', async data => {
			if (data['moduleType']) {
				setModuleAI(data['moduleType']);
			}
			if (data['userId'] == user['_id']) {
				// setTab(tab, true); // Get updated AI Report from Server
				setAiEnchaned(true);
				showMessage(data['message']);
				dispatch(toggleModal({ open: 'completeAIReport' }));
			}
		});
	}, [socket]);

	// TODO: Check if the data exists
	useEffect(() => {
		location.hash = tab;
		window.scroll(0, 450);

		if (tab == 'checklist-tasks' && checklistTask.length == 0) getChecklistTask(tab);
		if (tab == 'company-audit-log' && Object.values(auditReportLogs).length == 0) getChangeReports(tab);
		if (tab == 'diligence-pack-reporting' && Object.values(selectedModules).length == 0) getCompanyByModules(tab);
		if ((window.location.href.includes('fit-report') || tab.includes('fit-report')) && user['role'] == 'Investor') setIsPortfolio(true);
		if ((tab && company['testType'] && tab == `two-minute-test-${company['testType']}`) && user?.['role'] == 'Guest' && !user.firstName) return setIsTwoMinuteTesHomePage(true);
		else setIsTwoMinuteTesHomePage(false);

		if (tab && tab.includes('directors-questionnaire')) Array.from(openTabs).map(async openTab => {
			if (openTab.includes('directors-questionnaire')) {
				await openTabs.delete(openTab);
				setOpenTabs(new Set(Array.from(openTabs)));
				if (tab === openTab) await setTab("");
			}
		});

		if (tab && tab !== 'fit-report') openTabs?.add(tab);
		if (tab == '' && window.location.pathname.includes('company')) {
			if (user['role'] != 'Guest') dispatch(toggleModal({ open: '' }));
			setCompanyIndex(true);
		} else setCompanyIndex(false);
		let isClientFirm = localStorage.getItem('isClientFirm');
		if (isClientFirm) setCompanyIndex(true);
	}, [tab]);

	useEffect(() => {
		if (company['_id'] && !company['subscription']) {
			window.location.href = BILLING.replace(":id", company['_id']);
			showMessage("Company has no subscription");
		}
		if (user['role'] == 'Advisor' && company['advisors'] && company['advisors']?.[user['_id']] && company['advisors']?.[user['_id']]?.['type']) {
			permissionLevel = company['advisors']?.[user['_id']]?.['type'];
			setPermissionLevel(permissionLevel);
		}
		if (user['role'] == 'Investor' && company['investors'] && company['investors']?.[user['_id']] && company['investors']?.[user['_id']]?.['type']) {
			permissionLevel = company['investors']?.[user['_id']]?.['type'];
			setPermissionLevel(permissionLevel);
		}
	}, [company]);

	const getCompany = async (moduleName) => {

		const companyId = props && props?.isCallback ? props.companyId : location.pathname.split("/").slice(-1)[0];
		if (companyId.toLowerCase().includes('callback')) return;

		let res = '';
		if (moduleName && (Object.keys(moduleContent).includes(moduleName) || moduleName.includes('directors-questionnaire'))) res = await getRequest(`/company/get/${companyId}?moduleName=${[moduleName]}`);
		else res = await getRequest(`/company/get/${companyId}`);
		if (res['code'] == 200) {
			res.body.modules['kpi'] = { type: "kpi", sections: [] };
			for (let module of Object.values(res['body']['modules'])) {
				let content = module['type'].includes('directors-questionnaire') ? moduleContent['directors-questionnaire'] : moduleContent[module['type']]
				if (content) {
					module['heading'] = module['director'] ? `${content['heading']} - ${module['director']['name']} (${module['director']['designation']})` : content['heading'];
					module['aiEnhanced'] = content['aiEnhanced'];
					module['subheading'] = content['subHeading'];
					module['uploadFiles'] = content['uploadFiles'];
				}
			}

			res['body']['modules']['unaudited-certification'] = moduleContent['unaudited-certification'];
			if (company?.['modules']) res['body']['modules'] = company['modules'];

			const resultArray = Object.entries(res['body']['modules']).filter(([key, value]) => value?.stats?.completionScore > 0).map(([key]) => key);
			setUserModules(resultArray)
			setCompany(res['body']);
			if (!res?.['body']?.['stats']?.['completionScore'] && res['body']['subscription'] != 'Free')
				dispatch(toggleModal({ open: 'dataRepository' }));
			// else if(!res?.['body']?.['stats']?.['completionScore']) // TODO: Show the Modal for Free Account

			setCompanyId(companyId);
			localStorage.setItem('companyId', companyId);
			if (moduleName) setTab(moduleName);
		};
	};

	const getCompanyByModules = async (tabName) => {
		const companyId = props && props?.isCallback ? props.companyId : location.pathname.split("/").slice(-1)[0];
		if (companyId.toLowerCase().includes('callback')) return;

		if (Object.values(selectedModules).length == 0) {
			let res = '';
			if (userModules && (Object.keys(moduleContent).includes(userModules) || userModules.includes('directors-questionnaire'))) res = await getRequest(`/company/get/${companyId}?moduleName=${moduleName}`);
			else res = await getRequest(`/company/allReports/${companyId}`);

			if (res['code'] == 200) {
				for (let module of Object.values(res['body'])) {
					let content = module['type'].includes('directors-questionnaire') ? moduleContent['directors-questionnaire'] : moduleContent[module['type']]
					if (content) {
						module['heading'] = module['director'] ? `${content['heading']} - ${module['director']['name']} (${module['director']['designation']})` : content['heading'];
						module['aiEnhanced'] = content['aiEnhanced'];
						module['subheading'] = content['subHeading'];
						module['uploadFiles'] = content['uploadFiles'];
					}
				}
				await setSelectedModules(res['body'])
				await setCompanyId(companyId);
				localStorage.setItem('companyId', companyId);
			}
		}
		setTabName(tabName);
	};

	const getUser = async () => {
		let response = await getRequest(`/user`);
		if (response && response['code'] == 200) {
			let user = response?.['body']?.['user'];
			dispatch(updateUser(user));
		}
	};

	const getAdvisors = async () => {
		let response = await getRequest(`/user/getAdvisor`);
		if (response['code'] == 200 && response['body']) {
			setAdvisors(response['body']['advisors']);
			setInvestors(response['body']['investors'])
		};
	};

	const getFitInvestorFirms = async () => {
		let clientId = location.pathname.split('/')[2];
		let response = await getRequest(`/company/getFitInvestorFirms/${clientId}`);
		if (response['code'] == 200 && response['body']) {
			await setFitInvestorFirms(response['body']);
			dispatch(toggleModal({ open: 'firmReportResult' }));
		}
	};

	const getChangeReports = async (tabName) => {
		const companyId = props && props?.isCallback ? props.companyId : location.pathname.split("/").slice(-1)[0];
		if (companyId.toLowerCase().includes('callback')) return;

		if (Object.values(auditReportLogs).length == 0) {
			let res = await getRequest(`/log/changeReports/${companyId}`);
			if (res['code'] == 200) {
				await setAuditReportLogs(res['body'])
				await setCompanyId(companyId);
				localStorage.setItem('companyId', companyId);

			}
		}
		setTabName(tabName);
	};

	const getChecklistTask = async (tabName) => {
		const companyId = props && props?.isCallback ? props.companyId : location.pathname.split("/").slice(-1)[0];
		if (companyId.toLowerCase().includes('callback')) return;

		let res = await getRequest(`/task/get/${companyId}`);
		if (res['code'] == 200) {
			await setChecklistTask(res['body'])
			await setCompanyId(companyId);
			localStorage.setItem('companyId', companyId);
		}
		setTabName(tabName);
	};

	const downloadPDF = async () => {
		try {
			setIsLeadPdf(true);
			const wrapper = document.getElementById("leadsPDF");
			wrapper.classList.remove("db");
			wrapper.classList.remove("h60vh");
			wrapper.classList.remove("oa");
			const filename = 'Curated Investor Leads';
			const opt = { filename, jsPDF: { unit: 'mm', format: 'a2', orientation: 'p' } };
			await html2pdf().set(opt).from(wrapper).save();
			wrapper.classList.add("db");
			wrapper.classList.add("h60vh");
			wrapper.classList.add("oa");
			setTimeout(() => setIsLeadPdf(false), 1000);
		} catch (e) { console.error(e); }
	};

	const sanitiseTab = t => t.replace(/(-sync)?-report$/, "");
	const reloadModule = (resetTab, isAfterSync) => setTimeout(() => setTab(resetTab, true, isAfterSync), 1000)

	const setTab = async (tabName, reset, isAfterSync) => {
		if (tabName == "diligence-pack-reporting") {
			getCompanyByModules(tabName);
			return;
		}
		if (tabName == "company-audit-log") {
			getChangeReports(tabName);
			return;
		}
		if (tabName == "checklist-tasks") {
			getChecklistTask(tabName);
			return;
		}

		let moduleName = tabName && sanitiseTab(tabName);
		const companyId = props && props?.isCallback ? props.companyId : location.pathname.split("/").slice(-1)[0];

		if (reset || (tabName && company['modules']?.[moduleName]?.['sections'].length > 0 && !company['modules']?.[moduleName]?.['sections'][0]['questions'])) {
			let response = await postRequest(`/company/getModule`, { companyId, moduleName });

			if (response && company?.['modules']?.[moduleName]) company['modules'][moduleName] = { ...company['modules'][moduleName], ...response['body'][moduleName] };

			if (isAfterSync == true) {
				const dataList = ['lastSyncBy', 'lastSyned', 'unallocatedFiles'];
				response = await postRequest(`/company/getData`, { companyId, dataList });
				for (const dataItem of dataList) {
					if (dataItem in response['body'] && response['body'][dataItem] !== undefined) {
						company[dataItem] = response['body'][dataItem];
					}
				}
				company['syncRequired'] = false;
			}

			await setCompany(st => ({ ...st, company }));
		}
		await setTabName(tabName);
		if (moduleName == 'investor-lead') dispatch(toggleModal({ open: '' }));
		if (moduleName == 'repository' && company['firstVisitRepository'] == true) {
			dispatch(toggleModal({ open: 'firstSetupDataRepository' }));
			company['firstVisitRepository'] = false;
			setCompany(company);
		}
	};

	const moveToCompanyPage = () => {
		let { state } = urlSearchData(location['search']);
		let [companyId, verifier] = parseState(state);
		window.location.href = `/company/${companyId}#repository`;
	};

	const connectGDrive = () => {
		let { code, state: companyId } = urlSearchData(location['search']);
		dispatch(toggleModal({ open: 'connectingDataRoom' }));
		postRequest(`/company/connectGDrive`, { code, companyId }).then(response => {
			dispatch(toggleModal({ open: '' }));
			if (response && response['code'] == 200) dispatch(toggleModal({ open: 'createdDataRoom' }));
			else {
				showMessage(response['message']);
				setTimeout(() => setTab("repository"), 1000);
			}
		}).catch(e => showMessage('Failed to create Google Drive folders'));
	};

	const connectDropBox = async () => {
		let { code, state: companyId } = urlSearchData(location['search']);
		dispatch(toggleModal({ open: 'connectingDataRoom' }));
		postRequest(`/company/connectDropBox`, { code, companyId }).then(response => {
			dispatch(toggleModal({ open: '' }));
			if (response && response['code'] == 200) {
				dispatch(toggleModal({ open: 'createdDataRoom' }));
			} else {
				showMessage(response['message']);
				setTimeout(() => setTab("repository"), 1000);
			}
		}).catch(error => {
			showMessage('Failed to create Dropbox folders');
		})
	};

	const connectSharePoint = async () => {
		let { code, state, client_info } = urlSearchData(location['search']);
		const [companyId, verifier] = parseState(state);
		dispatch(toggleModal({ open: 'connectingDataRoom' }));
		postRequest(`/company/connectSharePoint`, { code, companyId, client_info, verifier }).then(response => {
			dispatch(toggleModal({ open: '' }));
			if (response && response['code'] == 200) {
				dispatch(toggleModal({ open: 'createdDataRoom' }));
			} else {
				response && showMessage(response['message']);
				setTimeout(() => setTab("repository"), 1000);
			}
		}).catch(error => {
			showMessage('Failed to create SharePoint folders');
		})
	};

	const urlSearchData = searchString => {
		if (!searchString) return {};
		return searchString.substring(1).split('&').reduce((result, next) => {
			let pair = next.split('=');
			result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
			return result;
		}, {});
	};

	const parseState = state => {
		if (state.length > 25) {
			const decode = base64.decode(state);
			return decode.split("==");
		}
		return [state, ""];
	};

	const handleUserChange = e => setEditUser({ ...editUser, [e.target.name]: e.target.type === "radio" ? e.target.id : e.target.value });
	const changeUser = (e) => {
		const updatedPermissions = { ...editUser.permissions, [e.target.name]: e.target.checked };
		setEditUser({ ...editUser, permissions: updatedPermissions, });
	};

	const handleMemberEdit = (user) => {
		user['permissions'] = company['memberPermissions'][user['_id']];
		dispatch(toggleModal({ open: 'editUser' }));
		setEditUser(user);
		setUserAction('Edit');
	};

	const resendInviteMember = async (memberId) => {
		let res = await postRequest('/user/resendInvite', { companyId, memberId });
		if (!res || res['code'] != 200) return;
		showMessage(res['message']);
		dispatch(toggleModal({ open: '' }));
	};

	const handleDeleteMember = (userId) => {
		setDeleteMemberId(userId);
		dispatch(toggleModal({ open: 'deleteMember' }))
	};

	const submitDeleteMember = async () => {
		if (deleteMemberId !== '') {
			let res = await postRequest('/user/deleteMember', { companyId, userId: deleteMemberId });
			showMessage(res['message']);
			if (!res || res['code'] != 200) return;
			getCompany();
		}
		dispatch(toggleModal({ open: '' }));
	};

	const findUser = async () => {
		let email = document.getElementById('searchEmail').value;
		let res = await postRequest(`/user/findUser`, { email, companyId });
		showMessage(res['message']);
		if (!res || res['code'] != 200) return;
		if (res['body']['found']) {
			setUserAction('Add');
			setEditUser(res['body']['user']);
		} else {
			editUser['email'] = document.getElementById('searchEmail').value;
			setEditUser(editUser);
			setUserAction('New');
		}
	};

	const saveUser = async () => {
		const permissions = editUser.permissions || {};
		const email = document.getElementById("userEmail").value;
		const lastName = document.getElementById("userLastName").value;
		const firstName = document.getElementById("userFirstName").value;
		const designation = document.getElementById("designation").value;

		let res = '';
		if (userAction == 'Edit') res = await postRequest('/user/editMember', { companyId, firstName, lastName, email, designation, permissions });
		else if (userAction == 'New') res = await postRequest('/user/newMember', { companyId, firstName, lastName, email, designation, permissions });
		else res = await postRequest('/user/addMember', { companyId, firstName, lastName, email, designation, permissions });

		if (!res || res['code'] != 200) return;
		getCompany();
		setEditUser({});
		setUserAction('Search');
		showMessage(res['message']);
		dispatch(toggleModal({ open: '' }));
	};

	const selectAllPermissions = (event) => {
		const isChecked = event.target.checked;

		setSelectAll(isChecked);
		let permissions = {};
		// company?.['sections'].filter(sec => sec['sectionNo'].startsWith("D0"))
		company?.['sections']?.filter(sec => sec['sectionNo'].startsWith("D0")).forEach((section) => {
			if (section.sectionNo.trim() !== "") permissions[section.sectionNo] = isChecked;
		});

		setEditUser({ ...editUser, permissions });
	};

	const openKPI = async () => {
		try {
			if (!company['keyPerformanceIndicators'] || Object.values(company['keyPerformanceIndicators']).length == 0) {
				showMessage('We are generation your KPI, try again later.');
				await getRequest(`/company/generateKPI/${companyId}`);
				getCompany();
			}
			setTab("kpi-report");
			dispatch(toggleModal({ open: '' }));
		} catch (e) { showMessage('Unable to generate KPI') }
	};

	const closeTab = (e, openTab) => {
		localStorage.removeItem('isDiligenceReport');
		if (e?.stopPropagation) {
			e.stopPropagation();
		}
		openTabs.delete(openTab);
		setOpenTabs(new Set(Array.from(openTabs)));
		dispatch(toggleModal({ open: '' }));
		setAiEnchaned(false);
		if (tab === openTab) setTab('');
	};

	const toggleReport = (e, reportType) => {
		let moduleBox = localStorage.getItem('moduleBox');
		if (tab.endsWith("-report")) {
			closeTab(e, tab);
			if (moduleBox == 'Bonus Module') setTab("");
			else if (tab === "kpi-report") setTab("two-minute-test-" + company.testType);
			else {
				if (tab == `business-audit-${company['testType']}`) enableAIEnhancedReporting();
				setTab((reportType || tab).replace(/(-sync)?-report$/, ""));
			}
		} else {
			if (tab == `business-audit-${company['testType']}`) enableAIEnhancedReporting();
			setTab(reportType || tab + "-report");
			scrollTo({ top: 0, behavior: "smooth" });
		}
		localStorage.setItem('moduleBox', '');
		e.target.innerText = tab.endsWith("-report") ? e.target.innerText.replace("CLOSE", "VIEW") : e.target.innerText.replace("VIEW", "CLOSE");
	};

	const twoMinuteTestAIReport = async (module) => {
		if (tab.includes('two-minute-test') && module['stats']['completionPercent'] < 100) showMessage('Please complete the Test!');
		else enableAIEnhancedReporting();
	};

	const enableAIEnhancedReporting = async () => {
		if (aiEnhanced && user['role'] !== 'Guest') {
			if (!tab.includes("report")) setTab(tab + "-report");
			showMessage('AI Report Generated. Check AI Enhanced Recommendations.');
			return;
		}

		dispatch(toggleModal({ open: 'generatingAIReport' }))
		let module;
		if (user['role'] == 'Guest' && tab == 'kpi-report') module = `two-minute-test-${company?.['testType']}`;
		else module = tab.replace('-report', '');
		const response = await postRequest(`/company/generateAIReport/${companyId}`, { module });
	};

	const calculateCertification = (company) => {
		let auditType = 'business-audit-' + company['testType'];
		let data = {
			'repository': {
				// completeness: 75,
				'completeness': company['modules']?.['repository']?.['stats']?.['completionPercent'] || 0
			}, 'business-audit': {
				'risk': company['modules']?.[auditType]?.['stats']?.['riskPercent'] || 0,
				'completeness': company['modules']?.[auditType]?.['stats']?.['completionPercent'] || 0
			}, 'warranty-disclosure': {
				'completeness': company['modules']?.['warranty-disclosure']?.['stats']?.['completionPercent'] || 0
			}, 'directors-questionnaire': {
				'risk': company?.['directorsStats']?.['riskPercent'] || 0,
				'completeness': company?.['directorsStats']?.['completionPercent'] || 0,
			}, 'esg': {
				'risk': company['modules']?.['esg']?.['stats']?.['riskPercent'] || 0,
				'completeness': company['modules']?.['esg']?.['stats']?.['completionPercent'] || 0
			}, 'software-development': {
				'risk': company['modules']?.['software-development']?.['stats']?.['riskPercent'] || 0,
				'completeness': company['modules']?.['software-development']?.['stats']?.['completionPercent'] || 0
			}, 'two-minute-test': {
				'risk': company['modules'][`two-minute-test-${company['testType']}`]?.['stats']?.['riskPercent'] || 0,
				'completeness': company['modules'][`two-minute-test-${company['testType']}`]?.['stats']?.['completionPercent'] || 0,
			}

		};

		let points = { 'Bronze': {}, 'Silver': {}, 'Gold': {} }, extraModules = { 'Bronze': {}, 'Silver': {}, 'Gold': {} };
		points['Bronze']['p1'] = data['repository']['completeness'] >= 75;
		points['Bronze']['p2'] = data['business-audit']['completeness'] >= 75 && data['business-audit']['risk'] <= 50;
		points['Bronze']['status'] = (points['Bronze']['p1'] && points['Bronze']['p2']) ? 'complete-button' : (points['Bronze']['p1'] || points['Bronze']['p2']) ? 'inprogrees-btn' : 'noy-yet-btn';
		points['Bronze']['active'] = points['Bronze']['status'] == 'complete-button';
		extraModules['Bronze']['p1'] = data['esg']['completeness'] >= 75 && data['esg']['risk'] <= 50;
		extraModules['Bronze']['p2'] = data['software-development']['completeness'] >= 75 && data['software-development']['risk'] <= 50;

		points['Silver']['p1'] = data['repository']['completeness'] >= 90;
		points['Silver']['p2'] = data['business-audit']['completeness'] >= 90 && data['business-audit']['risk'] <= 50;
		points['Silver']['p3'] = data['warranty-disclosure']['completeness'] >= 75;
		points['Silver']['p4'] = data['directors-questionnaire']['completeness'] >= 75 && data['directors-questionnaire']['risk'] <= 50;
		points['Silver']['status'] = (points['Silver']['p1'] && points['Silver']['p2'] && points['Silver']['p3'] && points['Silver']['p4']) ? 'complete-button' :
			(points['Silver']['p1'] || points['Silver']['p2'] || points['Silver']['p3'] || points['Silver']['p4']) ? 'inprogrees-btn' : 'noy-yet-btn';
		points['Silver']['active'] = points['Silver']['status'] == 'complete-button';
		extraModules['Silver']['p1'] = data['esg']['completeness'] >= 90 && data['esg']['risk'] <= 50;
		extraModules['Silver']['p2'] = data['software-development']['completeness'] >= 90 && data['software-development']['risk'] <= 50;

		points['Gold']['p1'] = data['repository']['completeness'] == 100;
		points['Gold']['p2'] = data['business-audit']['completeness'] == 100 && data['business-audit']['risk'] <= 25;
		points['Gold']['p3'] = data['warranty-disclosure']['completeness'] >= 90;
		points['Gold']['p4'] = data['directors-questionnaire']['completeness'] >= 90 && data['directors-questionnaire']['risk'] <= 50;
		points['Gold']['status'] = (points['Gold']['p1'] && points['Gold']['p2'] && points['Gold']['p3'] && points['Gold']['p4']) ? 'complete-button' :
			(points['Gold']['p1'] || points['Gold']['p2'] || points['Gold']['p3'] || points['Gold']['p4']) ? 'inprogrees-btn' : 'noy-yet-btn';
		points['Gold']['active'] = points['Gold']['status'] == 'complete-button';
		extraModules['Gold']['p1'] = data['esg']['completeness'] == 100 && data['esg']['risk'] <= 25;
		extraModules['Gold']['p2'] = data['software-development']['completeness'] == 100 && data['software-development']['risk'] <= 25;

		company['points'] = points;

		let cLevel = points['Bronze']['active'] && points['Silver']['active'] && points['Gold']['active'] ? 'Gold' :
			points['Bronze']['active'] && points['Silver']['active'] ? 'Silver' : points['Bronze']['active'] ? 'Bronze' : 'None';
		company['cLevel'] = cLevel;
		company['certificateNext'] = cLevel == 'Gold' ? 'All Done' : cLevel == 'Silver' ? 'Gold' : cLevel == 'Bronze' ? 'Silver' : cLevel == 'None' ? 'Bronze' : 'None';

		company['moduleCertification'] = {
			'repository': points['Gold']['p1'] ? 'Gold' : points['Silver']['p1'] ? 'Silver' : points['Bronze']['p1'] ? 'Bronze' : 'None',
			'business-audit': points['Gold']['p2'] ? 'Gold' : points['Silver']['p2'] ? 'Silver' : points['Bronze']['p2'] ? 'Bronze' : 'None',
			'warranty-disclosure': points['Gold']['p3'] ? 'Gold' : points['Silver']['p3'] ? 'Silver' : points['Bronze']['p3'] ? 'Bronze' : 'None',
			'directors-questionnaire': points['Gold']['p4'] ? 'Gold' : points['Silver']['p4'] ? 'Silver' : points['Bronze']['p4'] ? 'Bronze' : 'None',
			'esg': extraModules['Gold']['p1'] ? 'Gold' : extraModules['Silver']['p1'] ? 'Silver' : extraModules['Bronze']['p1'] ? 'Bronze' : 'None',
			'software-development': extraModules['Gold']['p2'] ? 'Gold' : extraModules['Silver']['p2'] ? 'Silver' : extraModules['Bronze']['p2'] ? 'Bronze' : 'None',
		}
		company['moduleCertification'][`two-minute-test-${company['testType']}`] = data['two-minute-test']['risk'] == 0 && data['two-minute-test']['completeness'] == 0 ? 'None' : data['two-minute-test']['risk'] <= 50 && data['two-minute-test']['completeness'] == 100 ? 'Pass' : 'Fail';

		let completePoints = 0, totalPoints = 0;
		let point = points[company['certificateNext']] || points[cLevel];
		if (point) Object.keys(point).forEach(key => {
			if (key.indexOf('p') >= 0) {
				totalPoints++;
				if (point[key]) completePoints++;
			}
		});
		company['certificateProgress'] = (completePoints / totalPoints) * 100;

		return company;
	};

	const calculateStats = (companyObject) => {
		companyObject = JSON.parse(JSON.stringify(companyObject));
		const wasRepositoryComplete = companyObject?.['modules']?.['repository']?.['stats']?.['completionPercent'] === 100;
		// for (const module of Object.values(companyObject.modules)) {
		let module = companyObject['modules'][sanitiseTab(tab)];
		if (module['stats']) module['stats'] = { minRiskScore: 0, maxRiskScore: 0, riskScore: 0, maxCompletionScore: 0, completionScore: 0 }
		if (module['sections']) {
			for (const section of Object.freeze(module['sections'])) {

				section['stats'] = { minRiskScore: 0, maxRiskScore: 0, riskScore: 0, maxCompletionScore: 0, completionScore: 0 };

				// TODO: For Expension packs aggregate all questions from SubFolders
				let questions = [];
				if (module['expansionPack']) {
					Object.values(section['subFolders']).forEach(subSection => questions = questions.concat(subSection['questions']));
					questions = questions.filter(question => question !== undefined);
				} else questions = section['questions'];

				if (questions) {
					for (const question of questions) {
						if (question["type"] === "repository" && companyObject["subscription"] == "Pro" && question["qNo"].split(".").length == 1) continue;
						else if (question["type"] === "repository" && companyObject["subscription"] == "Lite" && question["qNo"].split(".").length > 1) continue;

						if (!question['isSkipped']) {
							let answer = question?.['answer'] || {};
							if (["Completed", "Not Applicable"].includes(answer?.['status'])) {
								// if (["Completed", "Not Applicable"].includes(answer?.['status']) || module['type'] !== "repository" && answer?.['radioOption']) {
								if (answer?.['radioOption'] && answer?.['radioOption'] !== 'NC')
									// if(answer?.['radioOption'] && answer?.['radioOption'] !== 'NC' && ["Completed", "Not Applicable"].includes(answer?.['status']))
									section['stats']['completionScore'] += question['completionScore'] || 1;
								if (question['questionType'] == 'D/ND')
									section['stats']['riskScore'] += answer?.['radioOption'] == 'Disclosure' ? question['riskIfD']
										: answer?.['radioOption'] == 'No Disclosure' ? question['riskIfND']
											: answer?.['radioOption'] == 'Not Sure' ? question['riskIfNS'] : 0;
								else section['stats']['riskScore'] += question["riskIf" + answer?.['radioOption']] || 0;

								const allRiskScores = Object.keys(question).filter(k => k.startsWith("riskIf")).map(k => question[k]);
								section['stats']['minRiskScore'] += Math.min(...allRiskScores);
								section['stats']['maxRiskScore'] += Math.max(...allRiskScores);
							}

							section['stats']['maxCompletionScore'] += question['completionScore'] || 1;
						}
					}
				}

				if (section['stats']['completionScore'] == 0) section['stats']['riskPercent'] = 0
				else section['stats']['riskPercent'] = Math.round((section['stats']['riskScore'] - section['stats']['minRiskScore']) / (section['stats']['maxRiskScore'] - section['stats']['minRiskScore']) * 100);
				section['stats']['completionPercent'] = Math.round(section['stats']['completionScore'] / section['stats']['maxCompletionScore'] * 100);

				if (section['subFolders']) calculateSubFolderStats(module, section['subFolders'], questions);
			}
			module['stats'] = module.sections.reduce((stats, section) => {
				for (const key in section['stats'])
					stats[key] = (stats[key] ?? 0) + section['stats'][key];
				return stats;
			}, { minRiskScore: 0, maxRiskScore: 0, riskScore: 0, maxCompletionScore: 0, completionScore: 0 });

			if (module['type'] == 'repository' && companyObject['subscription'] == 'Lite') module["stats"]["maxCompletionScore"] = 20;

			if (module['stats']['completionScore'] == 0) module['stats']['riskPercent'] = 0
			else module['stats']['riskPercent'] = Math.round((module['stats']['riskScore'] - module['stats']['minRiskScore']) / (module['stats']['maxRiskScore'] - module['stats']['minRiskScore']) * 100);

			if (module['stats']['completionScore']) module['stats']['completionPercent'] = Math.round(module['stats']['completionScore'] / module['stats']['maxCompletionScore'] * 100);
		}
		// }

		companyObject['stats'] = Object.values(companyObject.modules).reduce((stats, module) => {
			if (module?.['stats']?.['completionScore'] > 0)
				for (const key in module['stats'])
					stats[key] = (stats[key] ?? 0) + module['stats'][key];
			return stats;
		}, { minRiskScore: 0, maxRiskScore: 0, riskScore: 0, maxCompletionScore: 0, completionScore: 0 });

		if (companyObject['stats']['completionScore'] == 0) companyObject['stats']['riskPercent'] = 0
		else companyObject['stats']['riskPercent'] = Math.round((companyObject['stats']['riskScore'] - companyObject['stats']['minRiskScore']) / (companyObject['stats'].maxRiskScore - companyObject['stats']['minRiskScore']) * 100);
		companyObject['stats']['completionPercent'] = Math.round(companyObject['stats']['completionScore'] / companyObject['stats'].maxCompletionScore * 100);

		if (!wasRepositoryComplete && companyObject?.modules?.repository?.['stats']?.['completionPercent'] == 100) dispatch(toggleModal({ open: 'respositoryCompleted' }));
		if (tab.includes('two-minute-test') && companyObject?.['modules']?.[tab]?.['stats']?.['completionPercent'] == 100) dispatch(toggleModal({ open: 'twoMinuteTestCompleted' }));

		calculateCertification(companyObject);
		companyObject = calculateDirectorStats(companyObject);

		return companyObject;
	};

	const calculateDirectorStats = (companyObj) => {
		let directorsCount = 0;
		if (companyObj['directorsStats']) {
			companyObj['directorsStats']['riskPercent'] = 0;
			companyObj['directorsStats']['completionPercent'] = 0;
		}
		for (let module of Object.values(companyObj['modules'])) {
			if (module['type'] && module['type'].includes('directors-questionnaire')) {
				if (!companyObj['directorsStats']) {
					companyObj['directorsStats'] = {
						riskPercent: module['stats']['riskPercent'],
						completionPercent: module['stats']['completionPercent']
					}
				} else {
					companyObj['directorsStats']['riskPercent'] += module['stats']['riskPercent'];
					companyObj['directorsStats']['completionPercent'] += module['stats']['completionPercent'];
				}
				directorsCount++;
			}
		}
		if (directorsCount > 0) {
			companyObj['directorsStats']['riskPercent'] = (companyObj['directorsStats']['riskPercent'] / directorsCount).toFixed(0);
			companyObj['directorsStats']['completionPercent'] = (companyObj['directorsStats']['completionPercent'] / directorsCount).toFixed(0);
		}

		const directorsStatsPercentage = {
			personal: { completionPercent: 0, riskPercent: 0 },
			occupational: { completionPercent: 0, riskPercent: 0 },
			personalFinantial: { completionPercent: 0, riskPercent: 0 },
			litigation: { completionPercent: 0, riskPercent: 0 }
		}
		let numofDirectors = 0
		for (const moduleKey in companyObj['modules']) {
			if (typeof companyObj['modules'][moduleKey] === 'object') {
				const module = companyObj['modules'][moduleKey];
				if (moduleKey.includes('directors-questionnaire')) {
					for (const section of module['sections']) {
						if (section['subFolders']) {
							for (const subFolderKey in section['subFolders']) {
								const subFolder = section.subFolders[subFolderKey];
								if (subFolder['sectionNo'] == "DEPF007.1") {
									directorsStatsPercentage['personal']['completionPercent'] += subFolder['stats']['completionPercent'];
									directorsStatsPercentage['personal']['riskPercent'] += subFolder['stats']['riskPercent'];
								}
								if (subFolder['sectionNo'] == "DEPF007.2") {
									directorsStatsPercentage['occupational']['completionPercent'] += subFolder['stats']['completionPercent'];
									directorsStatsPercentage['occupational']['riskPercent'] += subFolder['stats']['riskPercent'];
								}
								if (subFolder['sectionNo'] == "DEPF007.3") {
									directorsStatsPercentage['personalFinantial']['completionPercent'] += subFolder['stats']['completionPercent'];
									directorsStatsPercentage['personalFinantial']['riskPercent'] += subFolder['stats']['riskPercent'];
								}
								if (subFolder['sectionNo'] == "DEPF007.4") {
									directorsStatsPercentage['litigation']['completionPercent'] += subFolder['stats']['completionPercent'];
									directorsStatsPercentage['litigation']['riskPercent'] += subFolder['stats']['riskPercent'];
								}

							}
						}
					}
					numofDirectors++;
				}
			}

		}
		if (numofDirectors > 0) {
			for (const category in directorsStatsPercentage) {
				directorsStatsPercentage[category]['completionPercent'] /= numofDirectors;
				directorsStatsPercentage[category]['riskPercent'] /= numofDirectors;
			}
		}

		companyObj['directorSectionStats'] = directorsStatsPercentage;

		return companyObj;
	};

	const calculateSubFolderStats = (module, subFolders, questions) => {
		for (const subFolder of Object.values(subFolders)) {
			subFolder.stats = { minRiskScore: 0, maxRiskScore: 0, riskScore: 0, maxCompletionScore: 0, completionScore: 0 };

			let currentQuestions;
			if (subFolder['questions']) currentQuestions = subFolder['questions'];
			else currentQuestions = questions && questions.filter(q => q.qNo.match(new RegExp(subFolder.no + "((\.[0-9]+)|$)")));
			if (currentQuestions) {
				for (const question of currentQuestions) {
					if (!question['isSkipped']) {
						let answer = question['answer'] || {};
						if (["Completed", "Not Applicable"].includes(answer?.status)) {
							// if (["Completed", "Not Applicable"].includes(answer?.status) || module.type !== "repository" && answer?.['radioOption']) {
							if (answer?.['radioOption'] && answer?.['radioOption'] !== 'NC')
								subFolder.stats.completionScore += question.completionScore || 1;
							if (question['questionType'] == 'D/ND')
								subFolder.stats.riskScore += answer?.radioOption == 'Disclosure' ? question['riskIfD']
									: answer?.radioOption == 'No Disclosure' ? question['riskIfND']
										: answer?.radioOption == 'Not Sure' ? question['riskIfNS'] : 0;
							else subFolder.stats.riskScore += question["riskIf" + answer?.radioOption] || 0;

							const allRiskScores = Object.keys(question).filter(k => k.startsWith("riskIf")).map(k => question[k]);
							subFolder.stats.minRiskScore += Math.min(...allRiskScores);
							subFolder.stats.maxRiskScore += Math.max(...allRiskScores);
						}
						subFolder.stats.maxCompletionScore += question.completionScore || 1;
					}
				}
			}
			if (subFolder.stats.completionScore == 0) subFolder.stats.riskPercent = 0
			else subFolder.stats.riskPercent = Math.round((subFolder.stats.riskScore - subFolder.stats.minRiskScore) / (subFolder.stats.maxRiskScore - subFolder.stats.minRiskScore) * 100);
			subFolder.stats.completionPercent = Math.round(subFolder.stats.completionScore / subFolder.stats.maxCompletionScore * 100);

			if (subFolder.subFolders) calculateSubFolderStats(module, subFolder.subFolders, currentQuestions);
		}
	};

	const getCompletenessAndRiskHTML = (layoutType, stats, questions) => {
		if (stats) stats = JSON.parse(JSON.stringify(stats));
		if (questions) questions = JSON.parse(JSON.stringify(questions));

		let completenessColour = 9 - Math.floor(stats.completionPercent / 33.34);
		let completenessText = (stats.completionPercent === 100 ? "Completed" : (stats.completionPercent && stats.completionPercent > 0 ? stats.completionPercent + "% Complete" : "Not Started"))

		// If its 1 question
		if (questions && questions.length == 1) {
			completenessText = questions[0]?.['answer']?.status || 'Not Started';
			completenessColour = completenessText === 'Not Started' ? 9 : (completenessText == 'In Progress' || completenessText == 'Not Applicable') ? 8 : completenessText == 'Completed' ? 7 : 9;
		}

		if (layoutType.includes("repository") || layoutType.includes("investor-lead"))
			return <span className={"fw600 lh1 fs1-25rem mt1rem c" + completenessColour}>{completenessText}</span>

		let riskText, riskColour;
		if (stats.riskPercent === 0 && stats.completionPercent === 0) {
			riskColour = 6;
			riskText = "No Risk Score";
		} else if (stats.riskPercent > 62) {
			riskColour = 9;
			riskText = "High Risk";
		} else if (stats.riskPercent < 38) {
			riskColour = 7;
			riskText = "Low Risk";
		} else {
			riskColour = 8;
			riskText = "Medium Risk";
		}

		if (layoutType.includes("sidebar"))
			return <>
				<span className={`fw300 c${completenessColour} tac fw600 lh1 fs1-25rem mt1rem`}>{stats.completionPercent || 0}% Complete</span>
				{(!layoutType.includes("repository") || !layoutType.includes("investor-lead")) && <span className={`fw300 c${riskColour} tac fw600 lh1 fs1-25rem mt0-5rem`}>{riskText}</span>}
			</>;
		return <>
			<span className={"c" + completenessColour}>{completenessText}</span>
			<span> - </span>
			<span className={"c" + riskColour}>{riskText}</span>
		</>
	};

	const handleContactInvestor = async (e, investor) => {
		let investorId = investor['_id'];
		let clientId = location.pathname.split('/')[2];
		let res = await postRequest(`/company/contactInvestor`, { investorId, clientId, isContacted: e.target.checked });
		if (res['code'] == 200) getFitInvestorFirms();
		else showMessage(res['message']);
	};

	const handleInvestorEmail = async (investor) => {
		if (investor['isContacted']) {
			setAcceptEmail(false);
			await setInvestorEmail(investor);
			dispatch(toggleModal({ open: 'investorEmailSent' }));
		}
	};

	const sendInvestorEmail = async () => {
		let data = {
			investorId: investorEmail['_id'],
			investorName: investorEmail['name'],
			clientId: company['_id'],
			clientName: company['cName'],
			fitScorePercentage: investorEmail['fitScorePercentage'],
			contactId: investorEmail['contactId'],
			contactName: investorEmail['contactName'],
			email: investorEmail['contactEmail'],
		};

		if (investorEmail) {
			let res = await postRequest(`/company/sendInvestorEmail`, data);
			if (res['code'] == 200) {
				setInvestorEmail({});
				setAcceptEmail(false);
				dispatch(toggleModal({ open: '' }));
				showMessage(res['message']);
			}
		}
	};

	return (
		<AnimatedPage>
			<div className="container py2rem df fdc">
				{/* Overiew Section */}
				<div className={`pr oh bg1 p2rem shadow df jcsb mb2rem ${(company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || (company?.['advisors'] && Object.keys(company?.['advisors']).length > 0)) ? 'advisor-bg-lite' : (company?.['creatorId']?.['createdBy']?.['role'] == 'Investor' || (company?.['investors'] && Object.keys(company?.['investors']).length > 0)) ? 'investor-bg' : 'client-bg'}`}>
					<span className={`fs2rem fw700 tac pa lh1 center ttu usn wsn c4`}>
						{(company?.['logo']) &&
							<img src={company["logo"]} className="w6rem cp c1 shadow p0-5rem br50px mb0-5rem" />
						}<br />
						{tab ? <>Core<br />Workspace</> : <>Company<br />Dashboard</>}
					</span>
					<div className="df fdc jcsb">
						<h1 className="ass w100 text-gradient fsjrem fs2rem-sm lh1 mb1rem">{company.cName || "Loading..."}</h1>
						<p className="ass fs1rem mb0-5rem">Company Number: <span className="fw600">{company['ukRegNo']}</span></p>
						<p className="ass fs1rem mb0-5rem">Subscription: <span className="fw600 c7">
							{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || (company?.['advisors'] && Object.keys(company?.['advisors']).length > 0) || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor' || (company?.['investors'] && Object.keys(company?.['investors']).length > 0)) && company['subscription'] == 'Pro')
								? 'Portfolio PRO'
								: <>{company['subscription']}</>
							}
						</span></p>
						<p className="ass fs1rem mb0-5rem">Company Objective: <span className="fw600 ttc">{company.testType}</span></p>
						<p className="ass fs1rem mb0-5rem">Company Stage: <span className="fw600 ttc">{company?.['cStage']}</span></p>
						{company?.['creatorId']?.['createdBy'] && <p className="df aic ass fs1rem mb0-5rem">Created By:
							<span className="fw600 ttc mx0-5rem">{company?.['creatorId']?.['createdBy']['firstName'] + " " + company?.['creatorId']?.['createdBy']['lastName']}</span>
							<span key={company?.['creatorId']?.['createdBy']['_id']} data-tooltip={company?.['creatorId']?.['createdBy']['firstName'] ? (company?.['creatorId']?.['createdBy']['firstName'] + " " + company?.['creatorId']?.['createdBy']['lastName']) : "Register to show your name here"} className={`${profileClass} w2rem h2rem df jcc aic br100 mr0-5rem ${user['_id'] == company?.['creatorId']?.['createdBy']['_id'] && ' bw3px bss bo7'}`}>
								{company?.['creatorId']?.['createdBy']?.['profile']
									? <img src={company?.['creatorId']?.['createdBy']?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={company?.['creatorId']?.['createdBy'].firstName ? (company?.['creatorId']?.['createdBy'].firstName + " " + company?.['creatorId']?.['createdBy'].lastName) : "Register to show your name here"} />
									: company?.['creatorId']?.['createdBy']['firstName'] ? (company?.['creatorId']?.['createdBy']['firstName'][0] + company?.['creatorId']?.['createdBy']['lastName'][0]) : "?"}
							</span>
						</p>}
						<p className="ass fs1rem mb1rem">Data Room: <span className="fw600"> &nbsp;
							{company.dropBoxLink ? <Dropbox />
								: company.gDriveLink ? <GoogleDrive />
									: company.sharePointLink ? <SharePoint />
										: company['subscription'] == 'Free' ? 'N/A' : 'Not Connected'}</span>
						</p>
						<div className="ass df">
							<div className="df fdc mr2rem">
								<span className="ass mb0-5rem">Owner</span>
								{company['creatorId'] &&
									<span className={`w2rem h2rem df jcc aic br100 bg3 c1 ${company['creatorId']['_id'] == user['_id'] && ' bw3px bss bo7'}`}>
										{company?.['creatorId']?.['profile']
											? <img src={company['creatorId']['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={company['creatorId']['firstName'] ? (company['creatorId']['firstName'] + " " + company['creatorId']?.['lastName']) : "Register to show your name here"} />
											: <span className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={company['creatorId']['firstName'] ? (company['creatorId']['firstName'] + " " + company['creatorId']?.['lastName']) : "Register to show your name here"}>{company['creatorId']['firstName'] ? (company['creatorId']['firstName'][0] + company['creatorId']?.['lastName']?.[0]) : "?"}</span>
										}</span>
								}
							</div>
							{(user['role'] !== 'Advisor' && user['role'] !== 'Investor') &&
								<div className="df fdc mr2rem">
									<span className={`${user['email'] == 'temp' ? 'c12 ' : 'c4 '} ass mb0-5rem`}>Members</span>
									<div className="df" style={{ marginTop: '3px' }}>
										{company?.members?.length > 0 && company?.members?.map(member => (
											<span key={member['_id']} data-tooltip={member?.['lastName'] ? (member['firstName'] + " " + member?.['lastName']) : "Register to show your name here"} className={`${profileClass} w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem ${user['_id'] == member['_id'] && ' bw3px bss bo7'}`}>
												{member?.['profile']
													? <img src={member?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={member.firstName ? (member.firstName + " " + member?.lastName) : "Register to show your name here"} />
													: member['firstName'] ? (member['firstName'][0] + member?.['lastName']?.[0]) : "?"}
											</span>
										))}
										{user['email'] == 'temp'
											? <>
												<span data-tooltip="Register to add members" className="da-cursor cp w2rem h2rem bg5 br100 df jcc aic c6 fs1-5rem mr0-5rem">+</span>
												<span className="da-cursor c12 td250 ml1rem fs1rem cp fw500">All Members</span>
											</>
											: <>
												<span onClick={() => { setEditUser({}); dispatch(toggleModal({ open: 'editUser' })); setUserAction('Search'); }} className="cp w2rem h2rem bg5 br100 df jcc aic c6 fs1-5rem mr0-5rem">+</span>
												<span onClick={() => dispatch(toggleModal({ open: 'userList' }))} className="c3 c4-hover td250 ml1rem fs1rem cp fw500">All Members</span>
											</>}
									</div>
								</div>
							}
							{/* Advisors */}
							<div className="df fdc aic mr1rem">
								<span className={`${user['email'] == 'temp' ? 'c12 ' : 'c4 '} ass mb0-5rem`}>Advisors</span>
								{company['advisorData'] && company['advisorData'].length > 0
									? <div className="df">
										{company['advisorData']?.map(advisor => {
											return (
												<span key={advisor['_id']} data-tooltip={advisor['firstName'] ? (advisor['firstName'] + " " + advisor?.['lastName']) : "Register to show your name here"} className={`${profileClass} w2rem h2rem df jcc aic br100 bg4 c1 mr0-5rem ${user['_id'] == advisor['_id'] && ' bw3px bss bo7'}`}>
													{advisor?.['profile']
														? <img src={advisor?.['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={advisor.firstName ? (advisor.firstName + " " + advisor?.lastName) : "Register to show your name here"} />
														: advisor['firstName'] ? (advisor['firstName'][0] + advisor?.['lastName']?.[0]) : "?"}
												</span>
											)

										})}
									</div>
									: <div className="df">
										<img data-tooltip={user['email'] == 'temp' ? "Register to add advisors" : "No Advisor"} className={`${user['email'] == 'temp' && 'da-cursor '} shadow br100 w3rem h3rem`} src={require('../../../../images/profile-photo.jpeg')} />
									</div>
								}
							</div>
							{/* Investors */}
							<div className="df fdc aic ml1rem">
								<span className={`${user['email'] == 'temp' ? 'c12 ' : 'c4 '} ass mb0-5rem`}>Investors</span>
								{company['investorData'] && company['investorData'].length > 0
									? <div className="df">
										{company['investorData']?.map(investor => (
											<span key={investor['_id']} data-tooltip={investor['firstName'] ? (investor['firstName'] + " " + investor?.['lastName']) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 bg4 c1 mr0-5rem ${user['_id'] == investor['_id'] && ' bw3px bss bo7'}`}>
												{investor?.['profile']
													? <img src={investor?.['profile']} className="w2rem h2rem df jcc aic br100 bg4 c1" data-tooltip={investor.firstName ? (investor.firstName + " " + investor?.lastName) : "Register to show your name here"} />
													: investor['firstName'] ? (investor['firstName']?.[0] + investor['lastName']?.[0]) : "?"}
											</span>
										))}
									</div>
									: <div className="df">
										<img data-tooltip={user['email'] == 'temp' ? "Register to add investors" : "No Investor"} className={`${user['email'] == 'temp' && 'da-cursor '} shadow br100 w3rem h3rem`} src={require('../../../../images/profile-photo.jpeg')} />
									</div>
								}
							</div>
						</div>
					</div>
					<div className="df fdc fw500 mt2rem pr">
						<div className={`ttu progress-bar ${company?.['creatorId']?.['createdBy']?.['role'] == 'Investor' && 'investor-circle'}`} style={{ width: 70, height: 70 }}>
							<CircularProgressbar
								className={`${company?.['stats']?.['completionPercent'] > 67 ? 'green'
									: company?.['stats']?.['completionPercent'] > 33 ? 'orange' : 'red'}`}
								value={company?.['stats']?.['completionPercent'] || 0}
								text={`${company?.['stats']?.['completionPercent'] || 0}%`}
								styles={buildStyles({
									strokeLinecap: 'round',
									strokeWidth: '5px',
									textSize: '22px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#fc7785',
								})}
							/>
						</div>
						<span style={{ marginBottom: '15px' }} className="ttu text-center-area">Overall Completeness</span>
						<div className={`ttu progress-bar ${company?.['creatorId']?.['createdBy']?.['role'] == 'Investor' && 'investor-circle'}`} style={{ width: 70, height: 70 }}>
							<CircularProgressbar
								className={`${company?.['stats']?.['riskPercent'] > 67 ? 'red'
									: company?.['stats']?.['riskPercent'] > 33 ? 'orange' : 'green'}`}
								value={company?.['stats']?.['riskPercent'] || 0}
								text={company?.['stats']?.['completionPercent'] ? `${company?.['stats']?.['riskPercent'] || 0}%` : "0%"}
								styles={buildStyles({
									strokeLinecap: 'butt',
									strokeWidth: '5px',
									textSize: '22px',
									pathTransitionDuration: 0.5,
									trailColor: '#d6d6d6',
									backgroundColor: '#fc7785',
								})}
							/>
						</div>
						<span className="ttu text-center-area">Overall Risk</span>
						{company['cLevel'] && <>
							{company['subscription'] == "Free" || user['role'] == 'Guest'
								? <PassFailCertification className={'mla mra dashbaord-certficate-page mt0-5rem'} testType={company['testType']} level={company?.moduleCertification?.['two-minute-test-' + company['testType']]} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
								: <Certification className={'mla mra dashbaord-certficate-page mt0-5rem'} component='Company' level={company['subscription'] == "Lite" ? 'None' : company['cLevel']} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
							}</>
						}
						<span className="ttu">Diligentsia Certification</span>
					</div>
				</div>
				{/* Section Tabs */}
				{company['modules'] &&
					<div className="df">
						<div className={`df aic oxa w100 shadow`}>
							{Array.from(openTabs).map((openTab, idx) => {
								return (
									<Link to={`#${openTab}`} key={idx} onClick={() => setTab(openTab)} className={(tab === openTab ? "bg1" : "bo6") + " bss wsn usn td250 tab cp df fw600 ttu fs1rem jcc aic p1rem pr zi1 bw0px bbw2px bo1 tac"}>
										{company.modules[openTab]?.heading == undefined
											? <>{openTab.includes("directors-questionnaire")
												? "Directors Questionnaire REPORT"
												: <>{openTab.includes("fit-report")
													? 'Lead Report'
													: <>{openTab.replaceAll("-", " ")}</>
												}</>
											}</>
											: <>{openTab.includes("directors-questionnaire")
												? <>{company.modules[openTab]?.heading.split(",")[0]}</>
												: <>{company.modules[openTab]?.heading || company.modules[openTab.replace(/-report/, "")]?.heading + " Report"}</>
											}</>
										}
										{!isPortfolio && !companyIndex &&
											<Link to={window.location.pathname} onClick={e => closeTab(e, openTab)}>
												<svg className="w1rem f9 cp ml0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
													<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
												</svg>
											</Link>
										}
									</Link>
								)
							})}
						</div>
						{user?.['role'] == "Guest"
							? <Link data-tooltip='Company Index is disabled. You must register to access it.' className='new-shadow w200px bg16 df jcc aic px1rem py1rem pr zi1 bw0px bbw2px bo3 c2' style={{ cursor: "not-allowed" }}>Company Index</Link>
							: <>{companyIndex
								? <Link to={user?.['role'] == 'Advisor' ? `/advisor-dashboard` : user?.['role'] == 'Investor' ? `/investor-dashboard` : `/dashboard`}
									className="white w200px bg3 cp df jcc aic px1rem py1rem pr zi1 bw0px bbw2px bo3">
									{localStorage.getItem('isClientFirm')
										? 'Your Firm'
										: <>{user?.['role'] == 'Advisor' ? 'Client ' : user?.['role'] == 'Investor' ? 'Portfolio ' : 'Company '} Index</>
									}
								</Link>
								: <>{isPortfolio
									? <Link to={`/investor-dashboard`} onClick={() => setTab("")} className={`${!company["subscription"] && "op20 pen"
										} white w200px bg3 cp df jcc aic px1rem py1rem pr zi1 bw0px bbw2px bo3`}>Portfolio Index</Link>
									: <Link to={window.location.pathname} onClick={() => { localStorage.removeItem('isDiligenceReport'); setTab("") }} className={`${!company["subscription"] && "op20 pen"
										} white w200px bg3 cp df jcc aic px1rem py1rem pr zi1 bw0px bbw2px bo3`}>Back To Dashboard</Link>
								}</>
							}</>
						}
					</div>
				}

				{company['modules'] && (tab &&
					<div className="pr report-page">
						{tab.endsWith("-report")
							? <div className="td250 container bg1 py2rem shadow w100 df">
								<div className="w80 mr1rem dg bg5 py2rem">
									<Report getCompany={getCompany} profileClass={profileClass} user={user} company={company} aiEnhanced={aiEnhanced} setAiEnchaned={setAiEnchaned}
										moduleCertification={company?.moduleCertification[tab.includes('business-audit') ? 'business-audit' : sanitiseTab(tab)]}
										module={company.modules[sanitiseTab(tab)]} tab={tab} setTab={setTab} twoMinuteTestAIReport={twoMinuteTestAIReport} />
								</div>
								<SidebarIndex profileClass={profileClass} advisors={user['role'] == 'Advisor' ? advisors : investors} permissionLevel={permissionLevel} company={company} setCompany={setCompany} tab={tab} sanitiseTab={sanitiseTab} toggleReport={toggleReport} openKPI={openKPI} enableAIEnhancedReporting={enableAIEnhancedReporting}
									calculateStats={calculateStats} calculateSubFolderStats={calculateSubFolderStats} getCompletenessAndRiskHTML={getCompletenessAndRiskHTML} twoMinuteTestAIReport={twoMinuteTestAIReport} getFitInvestorFirms={getFitInvestorFirms} setTab={setTab} setOpenTabs={setOpenTabs} openTabs={openTabs} />
							</div>
							: tab.includes('fit-report') ? <FitReport user={user} company={company} moduleCertification={company?.moduleCertification['investor-lead']} module={company.modules['investor-lead']} tab={tab} setTab={setTab} getFitInvestorFirms={getFitInvestorFirms} />
								: tab === 'unaudited-certification' ? <Certificates profileClass={profileClass} user={user} company={company} setTab={setTab} />
									: tab === 'company-settings' ? <CompanySettings profileClass={profileClass} company={company} setCompany={setCompany} setTab={setTab} getCompany={getCompany} handleMemberEdit={handleMemberEdit} />
										: tab === 'test-certification' ? <TestCertificate profileClass={profileClass} company={company} level={company?.['moduleCertification']?.['two-minute-test-' + company['testType']]} tab={tab} setTab={setTab} />
											: tab === 'checklist-tasks' ? <ChecklistReport profileClass={profileClass} checklistTask={checklistTask} user={user} company={company} setTab={setTab} getChecklistTask={getChecklistTask} />
												: tab === 'diligence-pack-reporting' ? <>
													{company != {} && selectedModules != {} && <AllReports profileClass={profileClass} user={user} company={company} aiEnhanced={aiEnhanced} setAiEnchaned={setAiEnchaned}
														modules={selectedModules} tab={tab} setTab={setTab} />}
												</>
													: tab === 'company-audit-log' ? <CompanyAudit profileClass={profileClass} auditReportLogs={auditReportLogs} user={user} company={company} />
														: tab === 'benchmark-reporting' ? <BenchmarkReport profileClass={profileClass} user={user} company={company} tab={tab} setTab={setTab} />
															: ([`two-minute-test-${company['testType']}`, 'repository', `business-audit-${company['testType']}`, 'warranty-disclosure', 'investor-lead', 'software-development', 'esg'].includes(tab) || tab.includes('directors-questionnaire'))
																? <QuesAns profileClass={profileClass} advisors={user['role'] == 'Advisor' ? advisors : investors} permissionLevel={permissionLevel} tab={tab} setTab={setTab} sanitiseTab={sanitiseTab} company={company} setCompany={setCompany} companyId={companyId} getCompany={getCompany} openKPI={openKPI} toggleReport={toggleReport} enableAIEnhancedReporting={enableAIEnhancedReporting}
																	calculateStats={calculateStats} calculateSubFolderStats={calculateSubFolderStats} getCompletenessAndRiskHTML={getCompletenessAndRiskHTML} aiEnhanced={aiEnhanced} setAiEnchaned={setAiEnchaned} setOpenTabs={setOpenTabs} openTabs={openTabs}
																	reloadModule={reloadModule} twoMinuteTestAIReport={twoMinuteTestAIReport} getFitInvestorFirms={getFitInvestorFirms} isTwoMinuteTesHomePage={isTwoMinuteTesHomePage} />
																: <DDQuesAns profileClass={profileClass} advisors={user['role'] == 'Advisor' ? advisors : investors} permissionLevel={permissionLevel} tab={tab} setTab={setTab} sanitiseTab={sanitiseTab} company={company} setCompany={setCompany} companyId={companyId} getCompany={getCompany} openKPI={openKPI} toggleReport={toggleReport} enableAIEnhancedReporting={enableAIEnhancedReporting}
																	calculateStats={calculateStats} calculateSubFolderStats={calculateSubFolderStats} getCompletenessAndRiskHTML={getCompletenessAndRiskHTML} aiEnhanced={aiEnhanced} setAiEnchaned={setAiEnchaned}
																	reloadModule={reloadModule} twoMinuteTestAIReport={twoMinuteTestAIReport} getFitInvestorFirms={getFitInvestorFirms} isTwoMinuteTesHomePage={isTwoMinuteTesHomePage} />
						}
					</div> || <Dashboard profileClass={profileClass} getFitInvestorFirms={getFitInvestorFirms} permissionLevel={permissionLevel} user={user} company={company} getCompany={getCompany} setTab={setTab} />
				)}
			</div>

			{/* User List */}
			<Modal open={modal['open'] == 'userList'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw1000px br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 class="text-gradient fs2rem fw600 mb2rem">Members</h1>
					<div class="dg gg1rem mb2rem">
						{company['creatorId'] &&
							<div class="pr df aic fs1rem-sm">
								{company['creatorId']?.['profile']
									? <img src={company['creatorId']?.['profile']} className={`pen pa t0 l0 w3rem h3rem df jcc aic br100 ${profileClass}`} data-tooltip={company['creatorId'].firstName ? (company['creatorId'].firstName + " " + company['creatorId']?.lastName) : "Register to show your name here"} />
									: <span class={`pen pa t0 l0 w3rem h3rem df jcc aic br100 ${profileClass}`}>{company?.['creatorId']?.['firstName']?.[0]?.toUpperCase() + company?.['creatorId']?.['lastName']?.[0]?.toUpperCase()}</span>
								}
								<span style={{ marginLeft: "1.5rem" }} class="pl2rem br5px shadow h4rem w100 bg5 df aic dg gtcr7-md gg1rem">
									<span className="fs1rem">{company['creatorId']['firstName']}</span>
									<span className="fs0-75rem"> Owner </span>
									<span className="fs0-75rem"> {company['creatorId']['email']} </span>
									<span className='fs1rem tac'></span>
									<span className='fs0-75rem c7'>Active</span>
									<span className='fs0-75rem tac'> {company['creatorId']?.['lastLogin'] && <>Last Login:<br />
										<p className={((new Date().getTime() - new Date(company['creatorId']['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(company['creatorId']['lastLogin']).format('ll')}</p>
									</>}</span>
									<span className='fs1rem tac'></span>
								</span>
							</div>
						}
						{company['members'] && company['members'].map(user => (
							<div class="pr df aic fs1rem-sm">
								{user?.['profile']
									? <img src={user?.['profile']} className={`pen pa t0 l0 w3rem h3rem df jcc aic br100 ${profileClass}`} data-tooltip={user.firstName ? (user.firstName + " " + user?.lastName) : "Register to show your name here"} />
									: <span class={`pen pa t0 l0 w3rem h3rem df jcc aic br100 ${profileClass}`}>{user['firstName'][0]?.toUpperCase() + user?.['lastName']?.[0]?.toUpperCase()}</span>
								}
								<span style={{ marginLeft: "1.5rem" }} class="pl2rem br5px shadow h4rem w100 bg5 df aic dg gtcr7-md gg1rem">
									<span className="fs1rem">{user['firstName']}</span>
									<span className="fs0-75rem"> {user['designation']?.toUpperCase()} </span>
									<span className="fs0-75rem"> {user['email']} </span>
									<span className='fs1rem tac' onClick={() => handleMemberEdit(user)} ><button className="cp"> <i class="fa fa-edit" /> Edit </button></span>
									<span className={`fs0-75rem ${user['emailVerified'] ? ' c7' : ' c8'}`}>{user['emailVerified'] ? 'Active' : <p>Invite Sent <br />{moment(user['updatedAt']).format('ll')}</p>}</span>
									<span className='fs0-75rem tac'> {!user['emailVerified']
										? <><button className="cp" onClick={() => resendInviteMember(user['_id'])}> <i class="fa fa-history c8" /> Resend </button></>
										: <>Last Login:<br />
											<p className={((new Date().getTime() - new Date(user['lastLogin']).getTime()) / (1000 * 3600 * 24 * 30)) > 3 ? 'c9' : 'c7'}>{moment(user['lastLogin']).format('ll')}</p>
										</>}
									</span>
									<span className='fs1rem tac'> <button className="cp" onClick={() => handleDeleteMember(user['_id'])}> <i class="fa fa-trash c9" /> Delete </button> </span>
								</span>
							</div>
						))}
					</div>
					<span onClick={() => { dispatch(toggleModal({ open: 'editUser' })); setEditUser({}); setUserAction('Search'); }} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">New Member</span>
				</div>
			</Modal>

			{/* Edit User */}
			<Modal open={modal['open'] == 'editUser'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })); setSelectAll(false); }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 class="text-gradient fs2rem fw600 mb2rem">{userAction} User</h1>
					{userAction === 'Search'
						? <div class="df fdc gg1rem">
							<div className="pr">
								<input id="searchEmail" name="searchEmail" type="email" placeholder="Search Email" className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 512 512">
									<path d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z" className="fa-secondary"></path>
									<path d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z" className="fa-primary"></path>
								</svg>
							</div>
							<span id='NewUserStep' onClick={findUser} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">
								Search User
							</span>
						</div>
						: <div class="df fdc gg1rem">
							<div class="pr">
								<input id="userFirstName" name="firstName" type="text" placeholder="First Name" onChange={handleUserChange} value={editUser['firstName'] || ""}
									className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
									<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
								</svg>
							</div>
							<div className="pr">
								<input id="userLastName" name="lastName" type="text" placeholder="Last Name" onChange={handleUserChange} value={editUser['lastName'] || ""}
									className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
									<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
								</svg>
							</div>
							<div className="pr">
								<input id="userEmail" name="email" type="email" placeholder="Email" onChange={handleUserChange} value={editUser['email'] || ""} disabled={userAction == 'Edit' || userAction == 'Add' ? true : false}
									className="pl4rem shadow bg5 w100 br5px p1rem" />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 512 512">
									<path d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z" className="fa-secondary"></path>
									<path d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z" className="fa-primary"></path>
								</svg>
							</div>
							<div className="pr">
								<input id="designation" name="designation" type="text" placeholder="Job Title" className="pl4rem shadow bg5 w100 br5px p1rem" onChange={handleUserChange} value={editUser['designation'] || ""} />
								<svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="fa-primary" d="M133.3 308.8C56.91 326.9 0 395.4 0 477.3C0 496.5 15.52 512 34.66 512H184L133.3 308.8zM314.7 308.8L264 512h149.3C432.5 512 448 496.5 448 477.3C448 395.4 391.1 326.9 314.7 308.8z" /><path className="fa-secondary" d="M176 288l32 56l-31.99 135.1L184 512h80l7.988-32.05L240 344l32-56H176zM224 0C153.3 0 96 57.25 96 128s57.25 128 128 128s128-57.25 128-128S294.8 0 224 0z" /></svg>
							</div>
							<div id="userPermissions" className="dg gg0-5rem my1rem">
								<div className="tac df jcfs jcc">
									<span className="checkbox w30px h30px pr mr1rem">
										<input onChange={selectAllPermissions} className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px"
											type="checkbox" checked={selectAll} />
									</span>
									<span className="fs1rem fw600 mr1rem usn">All</span>
									<h2 className="c4 fs1-5rem ttu w100 tac mr5rem">Permissions</h2>
								</div>
								{company?.['sections'] && company?.['sections'].filter(sec => sec['sectionNo'].startsWith("D0")).map((section, idx) => {
									return (
										<label key={idx} for={"permissions" + section['sectionNo']} className="df cp fs1rem">
											<span className="checkbox w30px h30px pr mr1rem">
												<input
													type="checkbox"
													onChange={changeUser}
													name={section['sectionNo']}
													id={"permissions" + section['sectionNo']}
													className="bg5 shadow bo5 bw2px bss cp td250 w30px h30px br5px"
													checked={editUser.permissions && editUser.permissions[section['sectionNo']]}
												/></span>
											<span className="fs1rem fw600 mr1rem usn">{section['sectionNo']}</span>
											<span className="fs1-25rem fw400 usn">{section['sectionName']}</span>
										</label>
									)
								})}
							</div>
							<span onClick={() => saveUser()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">Save</span>
							<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
						</div>
					}
				</div>
			</Modal>

			{/* Generating AI Report */}
			<Modal open={modal['open'] == 'generatingAIReport'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">Generating AI Report</h1>
					<div className="tac">
						<p>🔮 <b>Conjuring Magic:</b> We're now summoning the power of AI to craft your Enhanced Report! Please stay with us during this magical incantation…</p>
						<img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
						<p>The gears of knowledge are turning, the scrolls of wisdom unfurling… Your report will be ready in just a twinkle! Thank you for your patience, esteemed ally!</p>
					</div>
					<div className="tac my1rem">
						<p>🔍 While our AI wizards craft your report in the background, why not embark on a mini-adventure of your own? Explore the realm and uncover its secrets. Don't worry, we'll sound the trumpets when your report is ready for its grand reveal! 🚀</p>
					</div>
					<button onClick={() => openKPI()} className="h100 mt0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
						<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="fa-primary" d="M339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /><path className="fa-secondary" d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /></svg>
						Check Free KPI
					</button>
					{/* {user['emailVerified'] &&
						<button onClick={() => { dispatch(toggleModal({ open: '' })); setTab('company-settings') }} className="h100 mt0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Company Settings
						</button>
					} */}
				</div>
			</Modal>

			{/* Complete AI Report */}
			<Modal open={modal['open'] == 'completeAIReport'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">AI Report Now Complete!​</h1>
					<div className="tac my1rem">
						<p>"🌟 Huzzah, Valiant Voyager! 🌟 The stars have aligned and the spells have been cast. Your AI-enhanced report has been meticulously crafted and is now ready for your discerning eyes. Unveil the magic and insights within... simply click below to embark on this revelation! 📜✨"​</p>
						<img className="h8rem my1rem" src={require(`../../../../images/firework.gif`)} alt="" />
					</div>
					{moduleAI !== '' &&
						<button onClick={() => { setTab(moduleAI + "-report", true); dispatch(toggleModal({ open: '' })) }} className={`${user['role'] == 'Guest' ? 'bg4 ' : 'bg3 '} h100 mt0-5rem mxa shadow bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic`}>
							<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="fa-primary" d="M339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /><path className="fa-secondary" d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /></svg>
							View AI Report
						</button>
					}
					{user['role'] !== 'Guest' &&
						<button onClick={() => dispatch(toggleModal({ open: '' }))} className="h100 mt0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							I’ll Return Later
						</button>
					}
				</div>
			</Modal>

			{/* First Setup Data Repository */}
			<Modal open={modal['open'] == 'firstSetupDataRepository'} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className={`oya bg20 shadow minw70 px8rem pt5rem pb4rem bsbb df fdc m1rem br20px pr`}>
					<button className='close-popup' onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: "relative", top: "4px" }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className="df fdc">
						<p className="c15 fs1-25rem fw400">First time?</p>
						<h1 className="c15 fs2rem fw400 mb2rem">3 Ways to populate our Data Repository</h1>
					</div>
					<div className="w100 df jcc">
						<img className="mr2-5rem" style={{ width: '300px', height: '270px' }} src={require('../../../../images/landingpage/first-time-repository-1.png')} alt='' />
						<img className="mr2-5rem" style={{ width: '400px', height: '270px' }} src={require('../../../../images/landingpage/first-time-repository-2.png')} alt='' />
						<img className="" style={{ width: '300px', height: '270px' }} src={require('../../../../images/landingpage/first-time-repository-3.png')} alt='' />
					</div>
					<div className='df jcc mt2rem'>
						<button className='w300px p1rem shadow c1 bg4 bg3-hover br35px my1rem cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation'
							onClick={() => { setTab('repository'), dispatch(toggleModal({ open: '' })) }}>Let’s Go!</button>
					</div>
				</div>
			</Modal >

			{/* Data Repository Setup Steps */}
			<Modal open={modal['open'] == 'DataRepositorySetupSteps'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw1000px br5px pr">
					<span className="pa t0 l0 m1rem ttu c7 fs1-5rem fw500">{company.subscription}</span>
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className='df jcc'>
						<h1 className="text-gradient fw600 tac mb1rem fs1-5em">{company.cName}</h1>
					</div>
					<div className='df jcc'>
						<h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia Filing Assistant</h2>
					</div>

					<div className='df jcc fdc aic'>
						<h2 className="repository-step">Step1. Select your data room folders/files to be ingested</h2>
						<svg xmlns="http://www.w3.org/2000/svg" className="pen h2rem" viewBox="0 0 24 24">
							<path d="M19.924 13.617A1 1 0 0 0 19 13h-3V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v10H5a1 1 0 0 0-.707 1.707l7 7a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 .217-1.09z" style={{ fill: '#4A67FF' }} />
						</svg>
						<h2 className="repository-step">Step2. With the use of AI we do our magic in the background and allocate your files to our Repository sections</h2>
						<svg xmlns="http://www.w3.org/2000/svg" className="pen h2rem" viewBox="0 0 24 24">
							<path d="M19.924 13.617A1 1 0 0 0 19 13h-3V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v10H5a1 1 0 0 0-.707 1.707l7 7a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 .217-1.09z" style={{ fill: '#4A67FF' }} />
						</svg>
						<h2 className="repository-step">Step3. You review the allocation of your files to our sections and reallocate if desired</h2>
						<svg xmlns="http://www.w3.org/2000/svg" className="pen h2rem" viewBox="0 0 24 24">
							<path d="M19.924 13.617A1 1 0 0 0 19 13h-3V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v10H5a1 1 0 0 0-.707 1.707l7 7a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 .217-1.09z" style={{ fill: '#4A67FF' }} />
						</svg>
						<h2 className="repository-step">Step4. You can (optionally) remove duplicated files using our deduper</h2>
					</div>
					<div className="df jcc fdr jcc aic mt2rem">
						<button onClick={() => dispatch(toggleModal({ open: '' }))} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp miw20 w40 ttu bsbb tac mb1rem df jcc aic mr1rem">Let's go to Filing Assistant</button>
					</div>
				</div>
			</Modal>

			{/* Connecting DataRoom */}
			<Modal open={modal['open'] == 'connectingDataRoom'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">{props.storageName || 'Data Room'} to be Connected!</h1>
					<div className="tac my1rem">
						<p className="mb0-5rem">Setting up a replica (backup) Diligentsia folder structure in your chosen cloud Data Room will take a few moments.  Once created, you will be able to sync between the two platforms, effectively creating a backup on your cloud.</p>
						{props.storageId != 'GDrive' && <p className="mb0-5rem fs0-75rem">If you already have a data room, You can also choose to copy files from other folders in your cloud folders to the Diligentsia Data Room folder in your cloud. You can  then import/sync them. Your files imported in this manner coloured differently depending upon their source.  You will then need to go into each section and mark that section as “complete”.​</p>}
						<p className="mb0-5rem">We keep an audit log of all changes and have comprehensive reporting.</p>
						{props.storageId == 'GDrive' && <img className="h10rem" src={require(`../../../../images/GDrive.gif`)} alt="" />}
						{props.storageId == 'dropbox' && <img className="h10rem" src={require(`../../../../images/dropbox.gif`)} alt="" />}
						{props.storageId == 'sharepoint' && <img className="h10rem" src={require(`../../../../images/sharepoint.gif`)} alt="" />}
					</div>
					<div className="df jcc"><span className="px1rem py0-5rem mr1rem fw600" >Patience!​</span></div>
				</div>
			</Modal>

			{/* Created DataRoom */}
			<Modal open={modal['open'] == 'createdDataRoom'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div class="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<div className="tac my1rem">
						<p className="mb0-5rem">Good news!  Your Cloud Data<br />Room has now been created.<br />You can now sync.​</p>
					</div>
					<div className="df jcc">
						<button className="w60 shadow bg4 bg3-hover c1 td250 br5px px1rem py0-5rem mr1rem fw600 cp ttu bsbb df jcc" onClick={() => { moveToCompanyPage(); }}>Let’s go!​</button>
					</div>
				</div>
			</Modal>

			{/* Delete Member */}
			<Modal open={modal['open'] == 'deleteMember'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600 pb1rem">Delete Member</h1>
					<div className="tac my1rem">
						<p>Are you certain you want to delete Member?</p>
					</div>
					<div className="df jcse">
						<button onClick={() => dispatch(toggleModal({ open: '' }))} className="h100 mt0-5rem shadow bg4 bg3-hover c1 td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Cancel</button>
						<button onClick={() => submitDeleteMember()} className="h100 mt0-5rem shadow bg3 bg4-hover c1 td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Submit</button>
					</div>
				</div>
			</Modal>

			{/* Invest Leads Result */}
			<Modal open={modal['open'] == 'firmReportResult'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="bg1 shadow w90 px2rem py3rem bsbb df fdc br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<div className="pa t0 l0 m1rem">
						<p class="text-gradient fs1rem fw500 ttc pb0-5rem">{company['cName']}</p>
						<p class="text-gradient fs0-75rem fw500 ttc pb0-5rem">Date: {moment(Date.now()).format('ll')}</p>
					</div>
					<h1 className="text-gradient fs2rem fw600 tac ttu pb0-5rem">Curated Investor Leads Report</h1>
					<p className="text-gradient fs1rem fw500 tac pb1rem">{fitInvestorFirms.length} curated leads waiting for you</p>
					<div class="pa t0 l0 m1rem investor-leads-tooltip" style={{ top: '80px' }}>
						<button onClick={() => downloadPDF()} className="mxa shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac">
							<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
							Download PDF
						</button>
						<span className="tooltip-area cp" style={{ top: '-10px' }}>
							<i className="icon">
								<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
								<div className="overlay-box" style={{ top: '100px' }}>
									<p>The Prompt Reponse Score is calculated as the percentage of leads supplied to the investor with initial response within 10 days.</p>
								</div>
							</i>
						</span>
					</div>
					<div class="pa t0 r0 m1rem" style={{ top: '80px' }}>
						<Link to="#investor-lead" onClick={() => setTab('investor-lead')} className="shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac mr1rem">Lead Module Questions</Link>
						<button onClick={() => { setTab(""), dispatch(toggleModal({ open: '' })) }} className="shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac">Dashboard</button>
					</div>
					<div className='df jcc fdc'>
						{isLeadPdf
							? <h1 className="ass w100 tac text-gradient fs3rem lh1 my10rem">Downloading...</h1>
							: <table id="leadsPDF" className="shadow bg1 db h60vh oa table-advisor investor-leads-tooltip">
								<thead className="ps t0">
									<tr>
										<th class="fs1rem">Investor Name</th>
										<th class="fs1rem">Website</th>
										{user['role'] == 'investor' && <>
											<th class="fs1rem">Completeness
												<span className="tooltip-area cp" style={{ top: '2px' }}>
													<i className="icon">
														<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
														<div className="overlay-box" style={{ top: '100px' }}>
															<p>This score is calculated on the responsiveness of this investor to  approaches via this platform – BUT please note that a response does NOT indicate successful funding.</p>
														</div>
													</i>
												</span>
											</th>
											<th class="fs1rem">Priority Score</th>
										</>}
										<th class="fs1rem">Fit Score
											<span className="tooltip-area cp" style={{ top: '2px' }}>
												<i className="icon">
													<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
													<div className="overlay-box" style={{ top: '100px' }}>
														<p>The fit score is calculated on the “Fit” between the requirements of your company as you have responded in this module, and this particular investor’s investment criteria.  Where two investors share the same Fit Score, we prioritise those investors with the highest Prompt Response Score and which have updated their criteria within our Portfolio Pro product.</p>
													</div>
												</i>
											</span>
										</th>
										<th class="fs1rem">Prompt Response Score</th>
										<th class="fs1rem">Fit Report</th>
										<th class="fs1rem">Contact Investor</th>
										<th class="fs1rem">Date Contacted</th>
										<th class="fs1rem">Contact Email</th>
										<th class="fs1rem">Accepted/Rejected</th>
										<th class="fs1rem">Archive?</th>
									</tr>
								</thead>
								<tbody>
									{fitInvestorFirms && fitInvestorFirms.length > 0
										? fitInvestorFirms.map(investor => (
											<tr className="fs0-75rem">
												<td className='c6'>{investor['name'] ? investor['name'] : '-'}</td>
												<td className='c6'>
													{investor['website']
														? <a href={investor['website']} target='_blank' class="cp c3 tdu">Website Link</a> : '-'
													}</td>
												{user['role'] == 'investor' && <>
													<td className='c6'>{investor['completeness'] ? investor['completeness'] + '%' : '-'}</td>
													<td className='c6'>{investor['priorityScore'] ? investor['priorityScore'] : '-'}</td>
												</>}
												<td className='c6'>
													<i class={`fa fa-circle mr1rem fs1rem ${investor['fitScorePercentage'] > 70 ? 'green' : investor['fitScorePercentage'] > 30 ? 'orange' : 'red'}`}></i>
													{investor['fitScorePercentage'] ? investor['fitScorePercentage'] + '%' : '-'}
												</td>
												<td className='c6'>{(investor['promptScore'] || investor['promptScore'] == 0) ? investor['promptScore'] : '-'}</td>
												<td className='c6'><Link className={`w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r`} style={{ position: 'relative', bottom: '0' }}
													to={`/company/${company['_id']}#fit-report:${investor['_id']}`} onClick={() => { dispatch(toggleModal({ open: '' })), setTab(`fit-report:${investor['_id']}`) }}>R</Link></td>
												<td className='c6'>
													<Switch className="lead-report-switch" color="success" name='isContacted' checked={investor['isContacted']} onChange={(e) => handleContactInvestor(e, investor)} />
												</td>
												<td className={`c6 ${!investor['isContacted'] && 'unselectable'}`}>
													{investor['dateContacted'] == "Nil"
														? <p>-</p>
														: moment(investor['dateContacted']).format('lll')
													}
												</td>
												<td className={`c6 ${!investor['isContacted'] && 'unselectable'}`}>
													{(investor['leadStatus'] == 'Accepted' || investor['leadStatus'] == 'Rejected')
														? <span className="tooltip-area cp" style={{ position: 'relative' }}>
															<i className="icon">
																<p className={`${investor['leadStatus'] == 'Accepted' ? 'green' : 'red'} fs0-75rem fw500`}>{investor['leadStatus']}</p>
																<div className="overlay-box">
																	<p>The lead has been {investor['leadStatus']} by the Investor.</p>
																</div>
															</i>
														</span>
														: <>{investor['contactEmail'] == ""
															? '-'
															: <a class={`cp c3 tdu fw500 ${!investor['isContacted'] && 'unselectable'}`} onClick={() => handleInvestorEmail(investor)}>{investor['isEmailSent'] ? 'Resend Email' : 'Send Email'} </a>
														}</>
													}
												</td>
												<td className={`c6 ${!investor['isContacted'] && 'unselectable'}`}>
													{investor['dateLeadStatus'] == "Nil"
														? <p>-</p>
														: moment(investor['dateLeadStatus']).format('lll')
													}
												</td>
												<td className={`c6 ${!investor['isContacted'] && 'unselectable'}`}>?</td>
											</tr>
										))
										: <tr className="pa fs1-25rem fw600 mt0-5rem" style={{ left: '35%' }}>No investor firms matched to fit score</tr>
									}
								</tbody>
							</table>
						}
					</div>
				</div>
			</Modal>

			{/* Investor Email Sent */}
			<Modal open={modal['open'] == 'investorEmailSent'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw800px pr pt3rem br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<img className="h2rem pa t0 l0 m1rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					<h1 className="text-gradient fw600">Investor Email</h1>
					<div className="tac my1rem oa">
						<p class="fs0-75rem taj px0-5rem">Dear <b>{investorEmail['name']} </b>, <br />
							Prediligenced Investment Lead From <b>{company['cName']} </b>
							We have pleasure in providing you a FREE introduction to <b>{company['cName']} </b>
							which we have prediligenced and which has scored an <b>{investorEmail['fitScorePercentage'] + '%'} </b> Fit Score against
							our understanding of your firm's  investment criteria.
							Diligentsia is a new low-cost automated platform assisted by AI, to help SMEs,
							their professional advisors and investors find investment, grow, and eventually
							exit. It was borne out of our frustration as angel investors in our receiving
							impressive pitch decks and then being unable to cost-effectively diligence their
							contents. With the aid of checklist processes to complete in each area, the
							company self-diligences itself, receives scores and recommendations for
							improvement, and creates a detailed diligence pack in the process, each focused
							on the company's stated objective - investment, growth or exit.
							Click here box to view your bespoked <Link target="_blank" to={`/company/${company['_id']}#fit-report:${investorEmail['_id']}`} class="c3 fw500 tdu">Fit Report </Link> - showing the fit between the applicant company and your firm's investment criteria.  You can change/improve
							these later. <br />
							Click here box to view the company's <Link target="_blank" to={`/company/${company['_id']}#fit-report:${investorEmail['_id']}_Diligence-Reports`} class="c3 fw500 tdu">Diligence Pack </Link>, consisting of reports its
							data repository, 20-section business audit (20 sections including legal, IP,
							material contracts etc), warranty disclosure schedule and directors'
							questionnaires.  The reports each have a completeness and risk score, which
							count towards Diligentsia certification - bronze, silver, gold.  Only companies
							that that reached gold certification have access to a module which matches their
							investment requirements to those of a database of suitable investors. The
							companies pay Diligentsia - the leads to your firm are FREE.
							Just as you receive reports on completeness and risk, we provide our companies
							reports on each investor's completeness of information we hold on them, and a
							prompt responsiveness score! <br />
							You can click here - <Link target="_blank" to={`/company/${company['_id']}#fit-report:${investorEmail['_id']}_About-the-Investor`} class="c3 fw500 tdu"> Your Profile </Link> - to view the profile we hold on you - your bio
							etc.  We recognise that you may not be the contact person within your firm who
							should deal with these initial approaches, and you can nominate a colleague to
							be the point person in your place. However, we wish to offer quality, not
							quantity of diligenced leads in both directions, and for this reason ask that you
							nominate a specific individual rather than a generic “enquiries @” email address.
							Diligentsia's module for investors - also FREE - and which you can activate
							separately, offers many other features such as benchmarking, KPI reporting, and
							automatic generation of detailed due diligence checklists bespoked to a
							particular applicant company and industry, each with automated completeness
							and risk scoring. <br />
							Automation CANNOT replicate what you and your team does in relation to the
							critical processes of team chemistry, understanding of the investment
							opportunity, ambition, route to market, competition etc that you undertake.  But
							Diligentsia CAN do a lot of the boring and time-consuming work in the foothills of
							those conversations, and we believe ultimately improve your firm's efficiency
							and investment performance. <br />
							If you would like to find out more about this, please set up a call with me here. In
							the meantime feel free to investigate the opportunity we have provided in this
							email. <br />
							Kind regards,</p>
					</div>
					<div className="df jcc mb1rem">
						<span className="checkbox w20px h20px pr">
							<input defaultChecked={acceptEmail} onChange={() => setAcceptEmail(!acceptEmail)} className="bg5 shadow bo5 bw2px bss cp td250 w20px h20px br5px" type="checkbox" />
						</span>
						<span className="c4 fw500 w100 fs0-75rem lh3 ml0-5rem usn mr2rem">In send this email I understand that the company's diligence reports will be made available that investor</span>
					</div>
					<div className="df jcse">
						<button onClick={() => { setInvestorEmail({}), dispatch(toggleModal({ open: '' })) }} className="h100 mt0-5rem shadow bg4 c1 td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Cancel</button>
						<button disabled={!acceptEmail} onClick={() => sendInvestorEmail()} className={`${acceptEmail ? 'bg3 c1 ' : 'bg5 c0 '} h100 mt0-5rem shadow td250 br5px px2rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic`}>Send Email</button>
					</div>
				</div>
			</Modal>

			{/* Two-Minute Test Completed */}
			<Modal open={modal['open'] == 'twoMinuteTestCompleted'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div class='mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw700px pr pt5rem br5px pr'>
					<button className='close-popup' onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className='pen fds h1rem f1 mr0-5rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
							<path className='pen fa-primary' d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
						</svg>
					</button>
					<img className='h2rem pa t0 l0 m1rem' src={require(`../../../../images/logo.svg`)} alt='TODO' />
					<div className='tac'>
						<h1 className='mb1-5rem'>Two Minute Test Completed</h1>
						<p className='mb1-5rem'>Whilst we prepare your report, please glance at your <b>BONUS KPI Report</b> which has been tailored to your business and suggests 10  useful business metrics, how to calculate them, and what your industry comparables could be.</p>
					</div>
					<div className='df jcc'>
						<button className='w40 shadow bg4 bg3-hover c1 td250 br5px px1rem py0-5rem mr1rem fw600 cp ttu bsbb df jcc aic' onClick={() => { openKPI(), dispatch(toggleModal({ open: '' })) }}>
							<svg className='pen fds h1rem f1 mr0-5rem' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path className='fa-primary' d='M339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z' /><path className="fa-secondary" d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM339.8 211.8l-128 128C206.3 345.3 199.2 348 192 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L192 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C350.7 183.1 350.7 200.9 339.8 211.8z" /></svg>
							Free KPI Report
						</button>
					</div>
				</div>
			</Modal>

		</AnimatedPage >
	);
}