import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { updateUser } from '../../reducers/userReducer';
import CheckoutForm from '../../components/CheckoutForm';
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import { BILLING, COMPANY } from "../../constants/routes";
import Certification from "../../components/Certification";

import { refreshCompanies } from '../../reducers/companiesReducer';
import { getRequest, postRequest, showMessage } from "../../config";
import PassFailCertification from "../../components/PassFailCertification";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";


export default function Dashboard() {

	const dispatch = useDispatch();
	const location = useLocation();
	const navigation = useNavigate();

	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);
	const companies = useSelector(state => state.companies);

	let [plan, setPlan] = useState("Pro");
	let [companyId, setCompanyId] = useState("");
	let [noCompanies, setNoCompanies] = useState(false);
	let [parentCompany, setParentCompany] = useState(null);
	let [profileClass, setProfileClass] = useState('client-profile');
	let [companiesGroup, setCompaniesGroup] = useState({}); // { companyId: '', group: '', percentage: '' }

	useEffect(() => {
		getCompanies();
		if (location.pathname.includes('gDriveCallBack')) connectGDrive();
		else if (location.pathname.includes('dropBoxCallBack')) connectDropBox();
		else if (location.pathname.includes('sharePointCallBack')) connectSharePoint();
		if (user) setProfileClass(user['role'] == 'Advisor' ? 'advisor-profile' : user['role'] == 'Investor' ? 'investor-profile' : 'client-profile')
	}, []);

	const getCompanies = async () => {
		let response = await getRequest(`/user/myProfile`);
		if (response['body']) {
			let { user, companies } = response['body'];
			let groups = companies.filter(({ group }) => group == 'Parent');
			let standard = companies.filter(({ group }) => group != 'Parent');
			companies = groups.concat(standard);

			if (companies.length == 0) setNoCompanies(true);

			dispatch(updateUser(user));
			dispatch(refreshCompanies(companies));
		};
	};

	const connectGDrive = async () => {
		let { code, state: companyId } = urlSearchData(location['search']);
		dispatch(toggleModal({ open: 'connectDataRoom' }));
		const response = await postRequest(`/company/connectGDrive`, { code, companyId });
		if (response) {
			showMessage(response['message']);
			setTimeout(() => navigation(`/company/${companyId}#repository`), 1000);
		}
	};

	const connectDropBox = async () => {
		let { code, state: companyId } = urlSearchData(location['search']);
		dispatch(toggleModal({ open: 'connectDataRoom' }));
		const response = await postRequest(`/company/connectDropBox`, { code, companyId });
		if (response) {
			showMessage(response['message']);
			setTimeout(() => navigation(`/company/${companyId}`), 1000);
		};
	};

	const connectSharePoint = async () => {
		let { code, state: companyId, client_info } = urlSearchData(location['search']);
		dispatch(toggleModal({ open: 'connectDataRoom' }));
		const response = await postRequest(`/company/connectSharePoint`, { code, companyId, client_info });
		if (response) {
			showMessage(response['message']);
			setTimeout(() => navigation(`/company/${companyId}`), 1000);
		};
	};

	const urlSearchData = searchString => {
		if (!searchString) return {};
		return searchString.substring(1).split('&').reduce((result, next) => {
			let pair = next.split('=');
			result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
			return result;
		}, {});
	};

	const handleChildCompany = async (el) => {
		if (!companiesGroup[el['companyId']]) companiesGroup[el['companyId']] = { companyId: el['companyId'] };
		if (el['selected']) companiesGroup[el['companyId']]['selected'] = el['selected'];
		if (el['group']) {
			document.getElementById(`group-${el['companyId']}`).value = el['group'];
			companiesGroup[el['companyId']]['group'] = el['group'];
		}
		if (el['percentage']) {
			document.getElementById(`percentage-${el['companyId']}`).value = el['percentage'];
			companiesGroup[el['companyId']]['percentage'] = el['percentage'];
		}
		if (el['event']?.['target']?.['checked'] == false) delete companiesGroup[el['companyId']];

		setCompaniesGroup(companiesGroup);
	};

	const handleCreateGroup = async () => {
		let isValid = true
		Object.keys(companiesGroup).map(key => {
			if (companiesGroup?.[key].percentage <= 100 && companiesGroup?.[key].percentage >= 0 && /^[a-zA-Z0-9\s]*$/.test(companiesGroup?.[key].percentage)) { }
			else { isValid = false }
		})
		if (isValid) {
			companiesGroup[parentCompany['_id']] = { companyId: parentCompany['_id'], group: 'Parent', selected: 'true' };
			let data = await Object.values(companiesGroup).filter(company => {
				if (!company['group']) company['group'] = 'Subsidiary';
				if (!company['percentage']) company['percentage'] = '0';
				if (company['selected'] == 'true') return company;
			});
			if (data && data.length > 1) {
				const res = await postRequest(`/company/addGroup`, { companiesGroup: data });
				if (res || res['code'] == 200) {
					getCompanies();
					setParentCompany(null);
					setCompaniesGroup({});
					dispatch(toggleModal({ open: '' }));
				}
			}
			else showMessage("Please select a company for group");
		} else { showMessage("Please enter valid percentage ") }

	};



	const handlePortfolioSubscription = async (company, paymentPlan) => {
		if (company['subscription']) {
			showMessage('Subscribed Already!');
			return;
		}
		setCompanyId(company['_id']);
		await setPlan(paymentPlan);
		dispatch(toggleModal({ open: 'subscriptionPayment' }));
	};

	const showCompanyDetails = (company) => {
		return <>
			<div className='df jcse w20rem'>
				<h4 className='fs1rem c6 mr1rem'>{company['cName']}</h4>
				<span className='w20 mb1rem'>
					<span className="ass mb0-5rem fs0-75rem">Plan</span>
					{company['subscription']
						? <h2 className="fs1rem c7 mra tal">
							{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && (company['advisors'] || company['investors']) && company['subscription'] == 'Pro')
								? 'Portfolio PRO'
								: <>{company['subscription']}</>
							}</h2>
						: <h2 className="fs1rem c8 mra tal">INACTIVE</h2>
					}
				</span>
			</div>
			<div className='df jcse'>
				<span className='w20 df username-area type-user'>
					<div className="df fdc">
						<span className="ass mb0-5rem fs0-75rem">Group</span>
						<span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={(company['group'] && company['group'] !== "Standard") ? company['group'] : 'Not Connected'} className={`w2rem h2rem df jcc aic br100 bg4 c1 mr0-5rem ${company['group'] ? 'fs1rem' : 'fs0-75rem'}`}>
							{(company['group'] && company['group'] !== "Standard") ? company['group'][0] : 'N/A'}
						</span>
					</div>
				</span>
				{user['emailVerified'] && company['progress'] != 'pending' && company['subscription'] &&
					<span className='w20 df username-area ml1rem mr1rem'>
						<div class="df fdc">
							<span class="ass mb0-5rem fs0-75rem">Settings</span>
							<Link data-tooltip={"Company Settings"} to={`/company/${company['_id']}#company-settings`} className="cp w2rem h2rem df jcc aic br100 c1 mr0-5rem c6 usn pr bsbb td250 c4-hover ass mr1rem pr">
								<svg data-tooltip={"Company Settings"} className="center-vertically f3 l0 w2rem pr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect data-tooltip={"Company Settings"} width="256" height="256" fill="none" /><circle data-tooltip={"Company Settings"} className='' cx="128" cy="128" r="48" stroke-linecap="round" stroke-linejoin="round" strokeWidth="8" /><path data-tooltip={"Company Settings"} className="fa-primary" d="M183.7,65.1q3.8,3.5,7.2,7.2l27.3,3.9a103.2,103.2,0,0,1,10.2,24.6l-16.6,22.1s.3,6.8,0,10.2l16.6,22.1a102.2,102.2,0,0,1-10.2,24.6l-27.3,3.9s-4.7,4.9-7.2,7.2l-3.9,27.3a103.2,103.2,0,0,1-24.6,10.2l-22.1-16.6a57.9,57.9,0,0,1-10.2,0l-22.1,16.6a102.2,102.2,0,0,1-24.6-10.2l-3.9-27.3q-3.7-3.5-7.2-7.2l-27.3-3.9a103.2,103.2,0,0,1-10.2-24.6l16.6-22.1s-.3-6.8,0-10.2L27.6,100.8A102.2,102.2,0,0,1,37.8,76.2l27.3-3.9q3.5-3.7,7.2-7.2l3.9-27.3a103.2,103.2,0,0,1,24.6-10.2l22.1,16.6a57.9,57.9,0,0,1,10.2,0l22.1-16.6a102.2,102.2,0,0,1,24.6,10.2Z" fill="none" stroke='#4a67ff' stroke-linecap="round" stroke-linejoin="round" strokeWidth="8" /></svg>
							</Link>
						</div>
					</span>
				}
				<span className='w20 df username-area'>
					<div class="df fdc">
						<span class="ass mb0-5rem fs0-75rem">Owner</span>
						{company?.['creatorId']?.['profile']
							? <img src={company['creatorId']['profile']} className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={company['creatorId']['firstName'] ? (company['creatorId']['firstName'] + " " + company['creatorId']?.['lastName']) : "Register to show your name here"} />
							: <span className={`w2rem h2rem df jcc aic br100 ${profileClass}`} data-tooltip={company['creatorId']['firstName'] ? (company['creatorId']['firstName'] + " " + company['creatorId']?.['lastName']) : "Register to show your name here"}>{company['creatorId']['firstName'] ? (company['creatorId']['firstName'][0] + company['creatorId']?.['lastName']?.[0]) : "?"}</span>
						}
					</div>
				</span>
			</div>
			<Link to={COMPANY.replace(":id", company['_id'])} className={`${!company['subscription'] && 'op20 pen '} tac dashboard-btn c1 bg3 br5px shadow px0-25rem py0-5rem fs0-75rem fw500`}>Dashboard</Link>
			{company?.['creatorId']?.['createdBy']
				? <Link onClick={() => handlePortfolioSubscription(company, 'Pro')} className={`${!company?.['subscription'] ? 'bg3 ' : 'bg4 '} tac dashboard-btn c1 br5px shadow px1rem py0-5rem fw500 fs0-75rem`}>{!company?.['subscription'] ? 'Subscribe Now' : 'Portfolio PRO'}</Link>
				: <Link data-testid="subscribeNow" to={BILLING.replace(":id", company['_id'])} className={`${company?.['subscription'] && user['email'] == 'temp' && 'op20 pen '} tac dashboard-btn c1 bg3 br5px shadow px1rem py0-5rem fw500 fs0-75rem`}>
					{company?.['subscription']
						? 'Upgrade'
						: 'Subscribe Now'
					}
				</Link>
			}
			<div className='progress-area progress-dashboard' style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
				<div className='progress-box'>
					<div className="progress-bar" style={{ width: 50, height: 50 }}>
						<CircularProgressbar
							className={`${company.stats.completionPercent > 67 ? 'green'
								: company.stats.completionPercent > 33 ? 'orange'
									: 'red'}`}
							value={company.stats.completionPercent || 0}
							text={(company.stats.completionPercent || 0) + "%"}
							styles={buildStyles({
								strokeLinecap: 'round',
								strokeWidth: '5px',
								textSize: '20px',
								pathTransitionDuration: 0.5,
								trailColor: '#d6d6d6',
								backgroundColor: '#fc7785',
								dominantBaseline: 'ideographic',
							})}
						/>
					</div>
					<span style={{ marginBottom: '0', fontSize: '11px' }} className="text-center-area">Completeness</span>
				</div>
				<div className='progress-box'>
					<div className="progress-bar" style={{ width: 50, height: 50 }}>
						<CircularProgressbar
							className={`${company.stats.riskPercent > 67 ? 'red'
								: company.stats.riskPercent > 33 ? 'orange'
									: 'green'}`}
							value={company.stats.riskPercent || 0}
							text={company.stats.completionPercent ? `${(company.stats.riskPercent || 0)}%` : "0%"}
							styles={buildStyles({
								strokeLinecap: 'butt',
								strokeWidth: '5px',
								textSize: '20px',
								pathTransitionDuration: 0.5,
								trailColor: '#d6d6d6',
								backgroundColor: '#fc7785',
								dominantBaseline: 'ideographic',
							})}
						/>
					</div>
					<span style={{ marginBottom: '0', fontSize: '11px' }} className="text-center-area">Risk</span>
				</div>
				<div className='certificate-area-dashboard ml2rem pr'>
					{(company['cLevel']) && (company['subscription'] == "Pro" || company['subscription'] == "Pro Plus") &&
						<>{company['subscription'] == "Free" || user['role'] == 'Guest'
							? <PassFailCertification className={'op100'} testType={company['testType']} level={company?.moduleCertification?.['two-minute-test-' + company['testType']]} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
							: <Certification className={'op100'} component='Dashboard' level={company['subscription'] == "Lite" ? 'None' : company['cLevel']} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
						}</>
					}
				</div>
			</div>
		</>
	};

	return (
		<>
			<AnimatedPage>
				<CheckoutForm plan={plan} companyId={companyId} />

				<div className="container py2rem df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Company Index" subheading="Company Status" />
					<div className="dg gtcr2 maw400px gg2rem mxa mb2rem">
						{user['email'] != 'temp' && <>
							<span onClick={() => { dispatch(toggleModal({ open: 'disclaimerModal' })); localStorage.removeItem('testType'); }} className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w100 bsbb tac mb1rem df jcc aic">Add Company</span>
							<span onClick={() => dispatch(toggleModal({ open: 'groupCompanies' }))} className={`${companies && companies.length <= 1 && 'op20 pen '} shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu w100 bsbb tac mb1rem df jcc aic`}>Create Group</span>
						</>}
					</div>
					{companies && companies.length > 0
						? companies.map((company, idx) => {
							return (
								<div key={idx} className="dropdown-company-dahboard">
									<div className="dg gtcr5-md gg1rem p1rem br20px shadow bg1 fs1rem mb2rem company-dashboard-sf">
										{showCompanyDetails(company)}
									</div>
									{company['childrenCompanies'] && company['childrenCompanies'].length > 0 && <>
										<div className="dropdown-content">
											{company['childrenCompanies'] && company['childrenCompanies'].map((childrenCompany, idx) => {
												return (
													<div key={idx} className="dg gtcr5-md gg1rem p1rem br20px shadow bg1 fs1rem mb2rem company-dashboard-sf">
														{showCompanyDetails(childrenCompany)}
													</div>
												)
											})}
										</div>
									</>}
								</div>
							)
						})
						: <>{noCompanies && <h1 className='tac text-gradient fs1-75rem lh2'>No Companies Added</h1>}</>
					}
				</div>
			</AnimatedPage>

			{/* Group Companies */}
			<Modal open={modal['open'] == 'groupCompanies'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 oya bg1 p3rem shadow w100 bsbb df fdc maw600px br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h2 className="c4 fs1-75rem ttu w100 tac mb1rem">Create Group</h2>
					<div className="df fdc fs1rem mb1rem">
						<p>We recommend that prior to creating a group of companies (you will be asked to identify a parent, and then attach subsidiary and associated companies to it, with their corresponding % ownerships), you FIRST CREATE ALL THE COMPANIES in the group​.</p>
					</div>
					<div className="df jcc fdc fdr-md aic fs1rem px1rem">
						<span onClick={() => dispatch(toggleModal({ open: 'createGroup' }))} className="shadow my0-5rem bg3 bg4-hover c1 td250 br5px p0-5rem fw600 cp ttu w40 bsbb tac">Let’s go!</span>
					</div>
				</div>
			</Modal>

			{/* Create Group */}
			<Modal open={modal['open'] == 'createGroup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
				<div className="mah90 mih35 bg1 shadow w100 p2rem bsbb df fdc m1rem maw700px br5px pr">
					<button className="close-popup" onClick={() => { dispatch(toggleModal({ open: '' })), setCompaniesGroup({}) }}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">Add a Group</h1>
					<div className="mra mla fs1rem w100">
						<div className="mla mra">
							<div className="df fdc w100">
								<div className="pr mt1rem">
									<Dropdown className="dropdown-add-group">
										<Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn cp pl4rem shadow bg5 w95 br5px p1rem pr'>
											{parentCompany?.['cName'] || "Please select Parent company"}
											<span className="icon-right pa r0 mr1rem">
												<i className="icon">
													<svg className="ml0-5rem w1rem h1rem f6 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
														<path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
													</svg>
												</i>
											</span>
											<svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
												<path className="fa-primary" d="M608 352h-96c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C640 366.4 625.6 352 608 352zM128 352H32c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C160 366.4 145.6 352 128 352zM368 352h-96c-17.62 0-32 14.38-32 32v96c0 17.62 14.38 32 32 32h96c17.62 0 32-14.38 32-32v-96C400 366.4 385.6 352 368 352zM384 0H256C238.4 0 224 14.38 224 32v96c0 17.62 14.38 32 32 32h128c17.62 0 32-14.38 32-32V32C416 14.38 401.6 0 384 0z" />
												<path className="fa-secondary" d="M584 262.4V352h-48V272h-192V352h-48V272h-192V352h-48V262.4C56 241.3 73.25 224 94.38 224H296V160h48v64h201.6C566.8 224 584 241.3 584 262.4z" />
											</svg>
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-content cp shadow fww">
											{companies && companies.map((company, idx) => {
												return (
													<Dropdown.Item key={idx} onClick={() => setParentCompany(company)}>
														<div className="item fs1rem m0-5rem mb1rem cp bg5">
															<div className="deta-dropdown-company df jcsb aic">
																<h4 className='fs1-25rem'>{company['cName']}</h4>
															</div>
														</div>
													</Dropdown.Item>
												)
											})}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					{parentCompany != null &&
						<div className='bottom-company-area'>
							{companies && companies.map((company, idx) => {
								if (company['_id'] !== parentCompany['_id'] && company['group'] !== "Parent" && !company?.isParent) {
									return (
										<div key={idx} className='company-detail-box df aic w95'>
											<span className="checkbox active w2rem h2rem pr mr1rem">
												<input onChange={(event) => handleChildCompany({ event, companyId: company['_id'], group: companiesGroup[company['_id']]?.['group'], selected: 'true' })}
													id={`childCompnay-${company['_id']}`}
													className="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px"
													type="checkbox"
												/>
											</span>
											<div className="company-detail df fww p1rem br10px shadow bg1 fs1rem mb1rem w100 aic">
												<h4 className='fs0-75rem mr1rem w9rem'>{company['cName']}</h4>
												<span className='mr0-5rem df jsc w6rem'>
													{company['subscription']
														? <h2 className="fs1rem c7 mra tal mr0-5rem w2rem">{company['subscription']?.toUpperCase()}</h2>
														: <h2 className="fs0-75rem c7 mra tal mr0-5rem w3rem">INACTIVE</h2>
													}
													<div className='df w4rem'>
														<span className='df username-area'>
															<div class="df aic" style={{ flexDirection: "column", alignItems: "end" }}>
																<span class="ass fs0-75rem mt0-5rem ml0-5rem mr0-2rem">{company['role']}</span>
																{user['profile']
																	? <img className={`w2rem h2rem df jcc aic br100 mr0-5rem ${profileClass}`} src={user['profile']} data-tooltip={user.firstName ? (user.firstName + " " + user?.lastName) : "Register to show your name here"} />
																	: <span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={user.firstName ? (user.firstName + " " + user?.lastName) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 mr0-5rem fs0-75rem ${profileClass}`}>{user.firstName ? (user.firstName[0] + user?.lastName?.[0]) : "?"}</span>
																}
															</div>
														</span>
													</div>
												</span>
												<div className='dropdown-company w4rem px1rem py0-5rem ml1rem'>
													<select defaultValue={companiesGroup[company['_id']]?.['group']} name="dropdown" className="fs0-75rem" onChange={(e) => handleChildCompany({ companyId: company['_id'], group: e.target.value })} id={`group-${company['_id']}`}>
														<option value='Subsidiary'>Subsidiary</option>
														<option value='Associate'>Associate</option>
													</select>
												</div>
												<div className='percentage-area ml1rem w5rem'>
													<span className='input-area'>
														<input defaultValue={companiesGroup[company['_id']]?.['percentage']} onChange={(e) => handleChildCompany({ companyId: company['_id'], group: companiesGroup[company['_id']]?.['group'], percentage: e.target.value })} placeholder='Enter Percentage %' id={`percentage-${company['_id']}`} type="text" className="fw600" min="1" max="100" />
													</span>
												</div>
											</div>
										</div>
									)
								}
							})}
						</div>
					}
					{companies?.filter((company, i, arr) => {
						return !company?.isParent && parentCompany?._id !== company?._id;
					}).length > 0 ? (
						<span
							onClick={() => handleCreateGroup()}
							id="createGroup"
							className={`${(parentCompany == null || companiesGroup.length == 0) && 'op20 pen '} mt0-5rem shadow bg3 bg4-hover c1 td250 br5px pt0-5rem pb0-5rem pl3rem pr3rem fw600 cp ttu bsbb tac df jcc aic`}
						>
							Create Group
						</span>
					) : <h4 className='fs1rem mr1rem c4 tac p1rem' style={{ background: '#fff5f7', borderRadius: '14px' }}>
						Only the primary company remains.You can not add the primary company as a subsidiary.
					</h4>}
				</div>
			</Modal>

			{/* User Detail */}
			<Modal open={modal['open'] == 'userDetail'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
				<div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs3rem tac lh1 fw600 mb2rem">Owner Detail</h1>
					<div className='df fdc iic mxa gg0-5rem'>
						<span className='w100' ><b>Name: &nbsp;</b> {user?.firstName + user?.lastName} </span>
						<span className='w100'><b>Email: &nbsp;</b> {user?.email} </span>
						<span className='w100'><b>Role: &nbsp;</b> {user?.role} </span>
					</div>
				</div>
			</Modal>

		</>
	);
}
