import { Link, Navigate, useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/routes";



const SOCIAL_HANDLER = [
    {
        icon: <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='cp' >
            < g clip-path="url(#clip0_0_2209)" >
                <path d="M1.78694 14V7.43079H0V5.06556H1.78694V3.04535C1.78694 1.45785 2.84249 0 5.27471 0C6.25947 0 6.98766 0.09177 6.98766 0.09177L6.93028 2.30049C6.93028 2.30049 6.18765 2.29347 5.37725 2.29347C4.50015 2.29347 4.35963 2.68638 4.35963 3.33851V5.06556H7L6.88512 7.43079H4.35963V14H1.78694Z" fill="#4267B2" />
            </g>
            <defs>
                <clipPath id="clip0_0_2209">
                    <rect width="7" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>,
        link: 'https://www.facebook.com/profile.php?id=61558782730536'
    },
    {
        icon:
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1105_22101)">
                    <path d="M13.2513 15H1.74885C0.782953 15 0 14.2171 0 13.2512V1.74883C0 0.782944 0.782953 0 1.74885 0H13.2511C14.217 0 15 0.782944 15 1.74883V13.251C15.0002 14.2171 14.2172 15 13.2513 15Z" fill="black" stroke="white" stroke-miterlimit="10" />
                    <path d="M2.40132 2.54297L6.5778 7.96445L2.375 12.3722H3.32096L7.00059 8.51306L9.97352 12.3722H13.1924L8.78086 6.64587L12.6928 2.54297H11.7469L8.35825 6.09707L5.62023 2.54297H2.40132ZM3.79239 3.21953H5.27114L11.8012 11.6958H10.3224L3.79239 3.21953Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1105_22101">
                        <rect width="15" height="15" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ,
        link: 'https://twitter.com/DiligentsiaDD'
    },
    {
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='cp'>
            <g clip-path="url(#clip0_0_2199)">
                <path d="M7.63281 19V8.87953H4.20251V19H7.63281ZM5.9181 7.49692C7.11431 7.49692 7.85889 6.71978 7.85889 5.74862C7.8366 4.75556 7.11436 4 5.9408 4C4.76743 4 4.00012 4.75558 4.00012 5.74862C4.00012 6.71983 4.74452 7.49692 5.89571 7.49692H5.918H5.9181ZM9.53147 19H12.9618V13.3482C12.9618 13.0458 12.9841 12.7436 13.0746 12.5274C13.3226 11.923 13.8871 11.2971 14.8346 11.2971C16.0759 11.2971 16.5725 12.2252 16.5725 13.5857V18.9999H20.0026V13.1969C20.0026 10.0883 18.3103 8.64193 16.0533 8.64193C14.2028 8.64193 13.3903 9.65626 12.939 10.3471H12.9619V8.87932H9.53155C9.57657 9.82897 9.53155 18.9998 9.53155 18.9998L9.53147 19Z" fill="#0A66C2" />
            </g>
            <defs>
                <clipPath id="clip0_0_2199">
                    <rect width="16" height="15" fill="white" transform="translate(4.00012 4)" />
                </clipPath>
            </defs>
        </svg>,
        link: 'https://www.linkedin.com/company/98930154'
    },
]

export default function NewFooter() {
    const navigate = useNavigate()
    return (
        // pf
        <footer className="w100 zi-1 b0 l0 bg1">
            <div className="container  py2rem py5rem-md dg footar-saf gtcr2a-md gtccr-xl">
                <div className="footer-column">
                    <Link to={ROUTES.HOME}>
                        <img className="mb2rem h3rem" src={require(`../../images/logo.svg`)} alt="TODO" />
                    </Link>
                    <p className="mb1rem w62 fw400 fs0-875rem lh3 c15">Diligentsia Limited, The Old Rectory, Nock Verges, Stoney Stanton, Leicestershire, LE9 4LR UK</p>
                    <p className="mb2rem w62 fw400 fs0-875rem lh3 c15">Diligentsia Limited is registered in England & Wales, Company Number 14256673, VAT number: GB 426 4891 73</p>

                </div>
                <div className="footer-column p0-5rem">
                    <h2 className="p0-5rem fw600 fs1-25rem c15 lh3">Quick Links</h2>
                    <Link className="db cpflp td250 c4-hover w80 fw400 fs0-875rem lh3 c15" to={ROUTES.NEW_ABOUT_US}>
                        About Us
                    </Link>
                    <Link className="db cpflp td250 c4-hover w80 fw400 fs0-875rem lh3 c15" to={ROUTES.PRICING_PLAN}>
                        Pricing
                    </Link>
                    <Link className="db cpflp td250 c4-hover w80 fw400 fs0-875rem lh3 c15" to={ROUTES.CONTACT_US}>
                        Contact Us
                    </Link>
                    <Link className="db cpflp td250 c4-hover w80 fw400 fs0-875rem lh3 c15" to={ROUTES.TERMS_CONDITION_COMPANY}>
                        Terms &amp; Conditions
                    </Link>
                    <Link className="db cpflp td250 c4-hover w80 fw400 fs0-875rem lh3 c15" to={ROUTES.POLICY_COOKIE}>
                        Policies
                    </Link>
                </div>
                <div className="footer-column p0-5rem">
                    <h2 className="p0-5rem fw600 fs1-25rem c15 lh3">Customers</h2>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_COMPANIES}>
                        Companies
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_ACCOUNTANT}>
                        Accountants
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_LAWYER}>
                        Lawyers
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_ANGEL_NETWORKS}>
                        Angel Networks
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_ANGELS}>
                        Angels
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_VCS}>
                        Venture Capital Firms
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.LANDING_PAGE_PES}>
                        Private Equity Firms
                    </Link>
                </div>
                <div className="footer-column p0-5rem">
                    <h2 className="p0-5rem fw600 fs1-25rem c15 lh3">Partner Programs</h2>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.PARTNER_PROGRAM}>
                        Joint Marketing Program
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.PARTNER_PROGRAM}>
                        Co-branded Expansion Packs
                    </Link>
                    <Link className="db cpflp td250 c4-hover fw400 fs0-875rem lh3 c15" to={ROUTES.PARTNER_PROGRAM}>
                        Your Internal Bespoked Checklists
                    </Link>
                </div>
                <div className="footer-column p0-5rem">
                    <h2 className="p0-5rem fw600 fs1-25rem c15 lh3">Social media</h2>
                    <div className="df aic gg1rem pl1rem">
                        {SOCIAL_HANDLER.map((obj, i, arr) => {
                            return (
                                <div className="df jcc" style={{ width: '7%' }}>
                                    <Link to={obj?.link} target="_blank" >
                                        {obj?.icon}
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div >
        </footer >
    );
}


