import React from 'react'
import WorkCard from './workCard'


const HowItsWorkSection = ({ data, refs, isAnimationVisible }) => {

    return (
        <div className="pr lwbg how-its-work">
            <div className='container work-container' style={{ padding: Number(data?.IMAGES_ARRAY?.length) === 4 && '40px 0px' }}>
                <h1 ref={refs?.current?.[3]} className={`tac fw500 fs2-125rem c21 tslp mb2rem ${isAnimationVisible[3] ? 'op1 tty0' : 'op0 ttx20'}`} style={{ lineHeight: '62.2px' }}>
                    {data?.title}
                </h1>
                {Number(data?.IMAGES_ARRAY?.length) === 3 &&
                    <div className="df jcsb w100 mt4rem mb4rem">
                        {data?.IMAGES_ARRAY?.map((workObj, i, arr) => {
                            return <WorkCard key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 1 }} />
                        })}
                    </div>
                }
                {Number(data?.IMAGES_ARRAY?.length) === 4 &&
                    <>
                        
                        <div className="df jcc mt4rem" >
                            {data?.IMAGES_ARRAY?.slice(0, 2)?.map((workObj, i, arr) => {
                                return <WorkCard isMargin={i === 1 && true} key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 1 }} />
                            })}
                        </div>
                        <div className="df jcc mt2rem mb4rem" >
                            {
                                data?.IMAGES_ARRAY?.slice(2, 4)?.map((workObj, i, arr) => {
                                    return <WorkCard isMargin={i === 1 && true} key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 3 }} />
                                })
                            }

                        </div>
                    </>
                }
                {Number(data?.IMAGES_ARRAY?.length) === 5 &&
                    <>
                        <div className="df jcsb mt4rem">
                            {data?.IMAGES_ARRAY?.slice(0, 3)?.map((workObj, i, arr) => {
                                return <WorkCard key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 1 }} />
                            })}
                        </div>
                        <div className="df jcc mt3rem w100 mb4rem" >
                            {data?.IMAGES_ARRAY?.slice(3, 5)?.map((workObj, i, arr) => {
                                return <WorkCard isMargin={i === 1 && true} key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 4 }} />
                            })}
                        </div>
                    </>
                }
                {Number(data?.IMAGES_ARRAY?.length) === 6 &&
                    <>
                        <div className="df jcsb mt4rem">
                            {data?.IMAGES_ARRAY?.slice(0, 3)?.map((workObj, i, arr) => {
                                return <WorkCard key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 1 }} />
                            })}
                        </div>
                        <div className="df jcc mt3rem w100 mb4rem" >
                            {data?.IMAGES_ARRAY?.slice(3, 6)?.map((workObj, i, arr) => {
                                return <WorkCard isMargin={i === 1 && true} key={`${workObj?.title}-${i}`} refs={refs} isAnimationVisible={isAnimationVisible} data={{ ...workObj, serialNumber: i + 4 }} />
                            })}
                        </div>
                    </>
                }
                {data?.description &&
                    <p id="not-sure-yet" className="fs1-25rem fw600 tac c22 mt2rem" style={{ lineHeight: '32px' }}>
                        {data?.description}
                    </p>
                }
            </div>
            <img src={require('../../../../images/landingpage/circle.png')} className="pa ar circle-animation" style={{ height: "15%", bottom: '-43px', left: '-55px', zIndex: '9' }} />
            <img src={require('../../../../images/landingpage/blue-animation-container.png')} className="pa blue-animation" style={{ height: "19%", top: '-78px', right: '0px' }} />
        </div>
    )

}
export default HowItsWorkSection